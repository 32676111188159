<template>
	<v-sheet class="main-detail">
		<div class="">
			<v-layout class="section-main-content">
				<div class="details-body">
					<div class="p-sticky bg-white">
						<v-layout class="align-center light-blue-bg px-4 py-2 flex-wrap">
							<v-flex md12 class="d-flex flex-wrap">
								<v-chip
									v-if="contractDetail.status == 1"
									color="cyan white--text"
									class="ml-2 mr-2 text-capitalize"
								>
									Draft
								</v-chip>
								<v-chip
									v-if="contractDetail.status == 2"
									color="green white--text"
									class="ml-2 mr-2 text-capitalize"
								>
									Accepted
								</v-chip>
								<v-chip
									v-if="contractDetail.status == 3"
									color="red white--text"
									class="ml-2 mr-2 text-capitalize"
								>
									Rejected
								</v-chip>
								<v-spacer></v-spacer>

								<div
									v-if="
										((contractDetail.status == 1 && contractDetail.status != 3) ||
											(contractDetail.status == 1 && contractDetail.status != 2)) &&
										getPermission('contract:approve')
									"
								>
									<v-btn
										depressed
										v-if="getPermission('contract:approve')"
										tile
										class="green darken-4 mr-2 my-auto"
										color="white--text"
										v-on:click="doAction('accepted')"
									>
										<v-icon class="mr-1" size="19">mdi-check</v-icon>
										Accept
									</v-btn>
									<v-btn
										depressed
										v-if="getPermission('contract:approve')"
										tile
										class="red darken-4 mr-2 my-auto"
										color="white--text"
										v-on:click="doAction('rejected')"
									>
										<v-icon class="mr-1" size="19">mdi-cancel</v-icon>
										Reject
									</v-btn>
								</div>

								<template v-if="contractDetail.status == 2 && contractDetail.convert_project == 0">
									<v-btn
										v-if="getPermission('project:create')"
										depressed
										tile
										class="blue darken-4 mr-2 my-auto"
										color="white--text"
										v-on:click="convertProject"
									>
										<v-icon class="mr-1" size="19">mdi-transfer</v-icon>
										Convert to project
									</v-btn>
								</template>
								<template v-if="contractDetail.convert_project == 1">
									<v-chip
										class="mr-2 text-capitalize"
										color="red white--text"
										x-small
										label
										v-if="contractDetail.convert_project == 1"
										>{{ "Project Created" }}</v-chip
									>
								</template>
								<v-btn
									depressed
									tile
									class="blue darken-4 mr-2 my-auto"
									color="white--text"
									v-on:click="updateContract(contractDetail.id)"
									v-if="contractDetail.status == 1 && getPermission('contract:update')"
								>
									<v-icon class="mr-1" size="19">mdi-pencil</v-icon>
									Edit
								</v-btn>
								<v-btn depressed tile class="my-auto" v-on:click="goBack()">
									<v-icon class="mr-1">mdi-keyboard-backspace</v-icon>
									back
								</v-btn>
							</v-flex>
							<p
								class="my-auto text-capitalize custom-heading"
								style="margin-top: -4px !important; font-size: 20px; font-weight: 600"
								v-if="contractDetail && contractDetail.customer_barcode"
							>
								{{ contractDetail.customer_company_name }} :-
								{{ contractDetail.customer_barcode }}
							</p>
							<v-flex md12 class="d-flex flex-wrap">
								<div class="w-25">
									<div class="mt-2">
										<!-- <pre>{{ project }}</pre> -->
										<span class="fw-600">Contact Person : </span>
										<span class="fw-500">
											<span class="fw-500 primary--text" v-if="contractDetail && contractDetail.contact_name">
												{{ contractDetail.contact_name }}</span
											>
											<em class="text-muted" v-else> no customer name</em>
										</span>
									</div>
								</div>
								<div class="w-25">
									<div class="mt-2">
										<!-- <pre>{{ project }}</pre> -->
										<span class="fw-600">Website: </span>
										<span class="fw-500 primary--text" v-if="contractDetail && contractDetail.website">
											{{ contractDetail.website }}</span
										>
										<em class="text-muted" v-else> no Website</em>
									</div>
								</div>
								<div class="w-25">
									<div class="mt-2">
										<span class="fw-600">Email : </span>
										<span
											class="fw-500 primary--text cursor-pointer"
											v-if="contractDetail && contractDetail.email_address"
											v-on:click="copyNumber(contractDetail.email_address)"
										>
											{{ contractDetail.email_address }}</span
										>
										<em class="text-muted" v-else> no email</em>
									</div>
								</div>
								<div class="w-25">
									<div class="mt-2">
										<!-- <pre>{{ project }}</pre> -->
										<span class="fw-600">Phone Number : </span>
										<span
											class="fw-500 primary--text cursor-pointer"
											v-if="contractDetail && contractDetail.phone_number"
											@click="copyNumber(contractDetail.phone_number)"
										>
											{{ contractDetail.phone_number }}</span
										>
										<em class="text-muted" v-else> no phone number</em>
									</div>
								</div>
							</v-flex>
							<v-flex md12 class="d-flex flex-wrap">
								<div class="w-25">
									<div class="mt-2">
										<!-- <pre>{{ project }}</pre> -->
										<span class="fw-600">Contract # : </span>
										<span class="fw-500">
											<b>{{ contractDetail.barcode }}</b>
											<!-- <v-chip
												class="mr-1 text-capitalize"
												color="cyan white--text"
												x-small
												v-if="contractDetail && contractDetail.barcode"
												>{{ contractDetail.barcode }}
											</v-chip> -->
										</span>
									</div>
								</div>
								<div class="w-25">
									<div class="mt-2">
										<span class="fw-600">Title : </span>
										<span class="fw-500" v-if="contractDetail && contractDetail.title">
											{{ contractDetail.title }}</span
										>
										<em class="text-muted" v-else> no title</em>
									</div>
								</div>
								<div class="w-25">
									<div class="mt-2 d-flex">
										<!-- <pre>{{ project }}</pre> -->
										<div style="min-width: fit-content"><span class="fw-600 mr-2">Service Type: </span></div>
										<div>
											<span
												class="fw-500"
												v-if="
													contractDetail &&
													contractDetail.checkbox_type &&
													contractDetail.checkbox_type.includes('seo')
												"
											>
												<Chip class="mr-2 mb-1" outlined text="SEO" text-color="" color="blue"></Chip>
											</span>
											<span
												class="fw-500"
												v-if="
													contractDetail &&
													contractDetail.checkbox_type &&
													contractDetail.checkbox_type.includes('hosting')
												"
											>
												<Chip class="mr-2 mb-1" outlined text="Hosting" text-color="" color="blue-grey"></Chip>
											</span>
											<span
												class="fw-500"
												v-if="
													contractDetail &&
													contractDetail.checkbox_type &&
													contractDetail.checkbox_type.includes('ssl')
												"
											>
												<Chip class="mr-2 mb-1" outlined text="SSL" text-color="" color="green"></Chip>
											</span>
											<span
												class="fw-500"
												v-if="
													contractDetail &&
													contractDetail.checkbox_type &&
													contractDetail.checkbox_type.includes('domain')
												"
											>
												<Chip class="mr-2 mb-1" outlined text="Domain" text-color="" color="red"></Chip>
											</span>
											<span
												class="fw-500"
												v-if="
													contractDetail &&
													contractDetail.checkbox_type &&
													contractDetail.checkbox_type.includes('web')
												"
											>
												<Chip class="mr-2 mb-1" outlined text="Web" text-color="" color="orange"></Chip>
											</span>
											<span
												class="fw-500"
												v-if="
													contractDetail &&
													contractDetail.checkbox_type &&
													contractDetail.checkbox_type.includes('maintenance')
												"
											>
												<Chip class="mr-2 mb-1" outlined text="Maintenance" text-color="" color="cyan"></Chip>
											</span>
											<span
												class="fw-500"
												v-if="
													contractDetail &&
													contractDetail.checkbox_type &&
													contractDetail.checkbox_type.includes('software')
												"
											>
												<Chip
													class="mr-2 mb-1"
													outlined
													text="Software"
													text-color=""
													color="blue darken-4"
												></Chip>
											</span>
										</div>
									</div>
								</div>

								<div class="w-25">
									<div class="mt-2">
										<!-- <pre>{{ project }}</pre> -->
										<span class="fw-600">Total Cost : </span>
										<span
											class="fw-500 primary--text cursor-pointer"
											v-if="contractDetail && contractDetail.cost"
										>
											{{ formatMoney(contractDetail.cost) }}
										</span>
										<em class="text-muted" v-else> no cost</em>
									</div>
								</div>
								<!-- <pre>{{ project }}</pre> -->
								<!-- <div class="w-25">
									<div class="mt-2">
										<span class="fw-600">Remark : </span>
										<span class="fw-500" v-if="contractDetail && contractDetail.remark">
											{{ contractDetail.remark }}</span
										>
										<em class="text-muted" v-else> no remark</em>
									</div>
								</div> -->
							</v-flex>

							<!-- <v-flex md12 class="d-flex flex-wrap">
								<div class="w-25">
									<div class="mt-2">
										
										<span class="fw-600">Customer # : </span>
										<span class="fw-500">
											<b>{{ contractDetail.customer_barcode }}</b>
										</span>
									</div>
								</div>
								<div class="w-25">
									<div class="mt-2">
										<span class="fw-600">Company Name : </span>
										<span class="fw-500" v-if="contractDetail && contractDetail.customer_company_name">
											{{ contractDetail.customer_company_name }}</span
										>
										<em class="text-muted" v-else> no title</em>
									</div>
								</div>
								<div class="w-25">
									<div class="mt-2">
										
										<span class="fw-600">Company UEN : </span>
										<span class="fw-500" v-if="contractDetail && contractDetail.customer_company_uen">
											{{ contractDetail.customer_company_uen }}</span
										>
										<em class="text-muted" v-else> no company uen</em>
									</div>
								</div>
								<div class="w-25">
									<div class="mt-2">
										
										<span class="fw-600">Sales Persons: </span>
										<span
											class="fw-500"
											v-if="salesPersonList && salesPersonList.attendies && salesPersonList.attendies.length"
										>
											<v-chip
												class="mb-1 mr-1"
												small
												v-for="(data, index) in salesPersonList.attendies"
												:key="index"
											>
												<v-avatar left small>
													
													<img v-if="data.profile_img" :src="data.profile_img" />
													<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
												</v-avatar>
												<span v-if="data && data.first_name">{{ data.first_name }}</span>
											</v-chip>
										</span>
										<em class="text-muted" v-else> no sales person </em>
									</div>
								</div>
							</v-flex> -->

							<!-- <v-flex md12 class="d-flex flex-wrap">
								<div class="w-25">
									<div class="mt-2">
										<span class="fw-600">Address Line 1 : </span>
										<span class="fw-500">
											<span class="fw-500" v-if="contractDetail && contractDetail.address_line_1">
												{{ contractDetail.address_line_1 }}</span
											>
											<em class="text-muted" v-else> no address line 1</em>
										</span>
									</div>
								</div>
								<div class="w-25">
									<div class="mt-2">
										<span class="fw-600">Address Line 2 : </span>
										<span class="fw-500" v-if="contractDetail && contractDetail.address_line_2">
											{{ contractDetail.address_line_2 }}</span
										>
										<em class="text-muted" v-else> no address line 2</em>
									</div>
								</div>
								<div class="w-25">
									<div class="mt-2">
										<span class="fw-600">Postal Code : </span>
										<span class="fw-500" v-if="contractDetail && contractDetail.postal_code">
											{{ contractDetail.postal_code }}</span
										>
										<em class="text-muted" v-else> no postal code</em>
									</div>
								</div>
								<div class="w-25">
									<div class="mt-2">
										<span class="fw-600">Country: </span>
										<span class="fw-500 text-capitalize" v-if="contractDetail && contractDetail.country">
											{{ contractDetail.country }}</span
										>
										<em class="text-muted" v-else> no country</em>
									</div>
								</div>
							</v-flex> -->
						</v-layout>
						<v-flex md12 class="px-4 mt-2 grey lighten-4">
							<v-tabs
								v-model="dataTab"
								background-color="transparent"
								color="blue"
								class="custom-tab-transparent border-bottom w-100"
								active-class="blue darken-4 text-white"
								hide-slider
								><v-tab href="#overview"> <v-icon small left>mdi-poll</v-icon>Overview </v-tab>
								<v-tab
									href="#seo"
									v-if="
										getValue(contractDetail, 'checkbox_type') &&
										getValue(contractDetail, 'checkbox_type').includes('seo')
									"
								>
									<v-icon small left>mdi-poll</v-icon>SEO
								</v-tab>
								<v-tab
									href="#host"
									v-if="
										getValue(contractDetail, 'checkbox_type') &&
										getValue(contractDetail, 'checkbox_type').includes('hosting')
									"
								>
									<v-icon small left>mdi-poll</v-icon>HOSTING
								</v-tab>
								<v-tab
									href="#ssl"
									v-if="
										getValue(contractDetail, 'checkbox_type') &&
										getValue(contractDetail, 'checkbox_type').includes('ssl')
									"
								>
									<v-icon small left>mdi-poll</v-icon>SSL
								</v-tab>
								<v-tab
									href="#web"
									v-if="
										getValue(contractDetail, 'checkbox_type') &&
										getValue(contractDetail, 'checkbox_type').includes('web')
									"
								>
									<v-icon small left>mdi-poll</v-icon>WEB
								</v-tab>
								<v-tab
									href="#domain"
									v-if="
										getValue(contractDetail, 'checkbox_type') &&
										getValue(contractDetail, 'checkbox_type').includes('domain')
									"
								>
									<v-icon small left>mdi-poll</v-icon>Domain
								</v-tab>
								<v-tab
									href="#maintenance"
									v-if="
										getValue(contractDetail, 'checkbox_type') &&
										getValue(contractDetail, 'checkbox_type').includes('maintenance')
									"
								>
									<v-icon small left>mdi-poll</v-icon>Maintenance
								</v-tab>
								<v-tab
									href="#software"
									v-if="
										getValue(contractDetail, 'checkbox_type') &&
										getValue(contractDetail, 'checkbox_type').includes('software')
									"
								>
									<v-icon small left>mdi-desktop-classic</v-icon>Software
								</v-tab>
								<v-tab href="#quotation-file" v-on:click="getQuotationFile">
									<v-icon small left>mdi-poll</v-icon>Quotation File
								</v-tab>
								<v-tab href="#activity"> <v-icon small left>mdi-poll</v-icon>Activity </v-tab>
							</v-tabs>
						</v-flex>
					</div>

					<div class="tab-layout-content project-tb-content px-4">
						<v-tabs-items v-model="dataTab" class="transparent">
							<v-tab-item value="overview">
								<div class="detail-card bg-white">
									<div
										class="card--header d-flex align-items-center pa-2 border-bottom"
										v-on:click="open_detail = !open_detail"
									>
										<div class="font-level-3-bold">Overview</div>
										<v-spacer></v-spacer>
										<v-icon>{{ open_detail ? "mdi-menu-down" : "mdi-menu-right" }}</v-icon>
									</div>
									<div class="card--body d-flex align-items-center pa-2" v-if="open_detail">
										<table class="w-70">
											<tr>
												<td class="fw-600 px-3 py-2">Sales Person</td>
												<td class="fw-600 px-3 py-1">
													<span class="fw-500" v-if="contractDetail && contractDetail.user_name">
														<v-chip
															class="mb-1 mr-1"
															small
															v-for="(atten, index) in salesPersonList?.attendies"
															:key="index"
														>
															<v-avatar left small>
																<img v-if="atten.profile_img" :src="atten.profile_img" />
																<img
																	v-else
																	src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
																/>
															</v-avatar>
															<span v-if="atten && atten.display_name">{{ atten.display_name }}</span>
														</v-chip>
													</span>
													<em class="text-muted" v-else> no sales person</em>
												</td>
											</tr>
											<tr>
												<td class="fw-600 px-3 py-2">Company UEN</td>
												<td class="fw-600 px-3 py-1">
													<template v-if="contractDetail && contractDetail.customer_company_uen">
														{{ contractDetail.customer_company_uen }}</template
													>
													<template v-else> <em class="text-muted">no company uen</em></template>
												</td>
											</tr>

											<tr>
												<td class="fw-600 px-3 py-2">Address 1</td>
												<td class="fw-600 px-3 py-1">
													<template v-if="contractDetail && contractDetail.address_line_1">
														{{ contractDetail.address_line_1 }}</template
													>
													<template v-else> <em class="text-muted">no address line 1</em></template>
												</td>
											</tr>
											<tr>
												<td class="fw-600 px-3 py-2">Address 2</td>
												<td class="fw-600 px-3 py-1">
													<template v-if="contractDetail && contractDetail.address_line_2">
														{{ contractDetail.address_line_2 }}</template
													>
													<template v-else> <em class="text-muted">no address line 2</em></template>
												</td>
											</tr>
											<tr>
												<td class="fw-600 px-3 py-2">Unit No</td>
												<td class="fw-600 px-3 py-1">
													<template v-if="contractDetail && contractDetail.unit_number">
														{{ contractDetail.unit_number }}</template
													>
													<template v-else> <em class="text-muted">no unit number</em></template>
												</td>
											</tr>
											<tr>
												<td class="fw-600 px-3 py-2">Country</td>
												<td class="fw-600 px-3 py-1" style="text-transform: capitalize">
													<template v-if="contractDetail && contractDetail.country">
														{{ contractDetail.country }}</template
													>
													<template v-else> <em class="text-muted">no Country</em></template>
												</td>
											</tr>
											<tr>
												<td class="fw-600 px-3 py-2">Postal Code</td>
												<td class="fw-600 px-3 py-1">
													<template v-if="contractDetail && contractDetail.postal_code">
														{{ contractDetail.postal_code }}</template
													>
													<template v-else> <em class="text-muted">no postal code</em></template>
												</td>
											</tr>
											<tr>
												<td class="fw-600 px-3 py-2">Customer Remark</td>
												<td style="min-width: 25vw; max-width: 25vw" class="fw-600 px-3 py-1">
													<div class="text-justify">
														<template v-if="contractDetail && contractDetail.customer_remark">
															{{ contractDetail.customer_remark }}</template
														>
														<template v-else> <em class="text-muted">no Customer Remark</em></template>
													</div>
												</td>
											</tr>
											<tr>
												<td class="fw-600 px-3 py-2">Contract Remark</td>
												<td style="min-width: 25vw; max-width: 25vw" class="fw-600 px-3 py-1">
													<div class="text-justify">
														<template v-if="contractDetail && contractDetail.remark">
															{{ contractDetail.remark }}</template
														>
														<template v-else> <em class="text-muted">no Contract Remark</em></template>
													</div>
												</td>
											</tr>
										</table>
									</div>
								</div>
							</v-tab-item>

							<v-tab-item value="seo">
								<ContractSoe
									:customer-id="contractDetail.customer"
									:contract-id="contractDetail.id"
									:customer-service="servicesDetails"
									:contarct="contractDetail"
								>
								</ContractSoe>
							</v-tab-item>

							<v-tab-item value="host">
								<div class="detail-card bg-white">
									<div
										class="card--header d-flex align-items-center pa-2 border-bottom"
										v-on:click="open_detail = !open_detail"
									>
										<div class="font-level-3-bold">Hosting Details</div>
										<v-spacer></v-spacer>
										<v-icon>{{ open_detail ? "mdi-menu-down" : "mdi-menu-right" }}</v-icon>
									</div>
									<div class="card--body d-flex align-items-center pa-2" v-if="open_detail">
										<template
											v-if="customer_hosting && customer_hosting[0] && customer_hosting[0].type == 'client'"
										>
											<table class="w-100">
												<tr>
													<td class="fw-600 px-3 py-2">Type</td>
													<td class="fw-600 px-3 py-1">
														<tr>
															<td
																class="custom-table-details"
																v-if="customer_hosting && customer_hosting[0] && customer_hosting[0].company_type"
															>
																<span class="text-capitalize">{{ customer_hosting[0].company_type }}</span>
															</td>
														</tr>
													</td>
												</tr>
												<tr>
													<td class="fw-600 px-3 py-2">Remarks</td>
													<td class="fw-600 px-3 py-1">
														<tr>
															<td
																class="custom-table-details"
																v-if="customer_hosting && customer_hosting[0] && customer_hosting[0].remark"
															>
																{{ customer_hosting[0].remark }}
															</td>
															<td v-else>
																<em class="text-muted">no remark</em>
															</td>
														</tr>
													</td>
												</tr>
											</table>
										</template>
										<template v-else>
											<table class="w-100">
												<tr>
													<td class="fw-600 px-3 py-2">Type</td>
													<td class="fw-600 px-3 py-1">
														<tr>
															<td
																class="custom-table-details"
																v-if="customer_hosting && customer_hosting[0] && customer_hosting[0].company_type"
															>
																<span class="text-capitalize"> {{ customer_hosting[0].company_type }}</span>
															</td>
														</tr>
													</td>
												</tr>
												<tr>
													<td class="fw-600 px-3 py-2">Remark</td>
													<td class="fw-600 px-3 py-1">
														<tr>
															<td
																class="custom-table-details"
																v-if="customer_hosting && customer_hosting[0] && customer_hosting[0].remark"
															>
																{{ customer_hosting[0].remark }}
															</td>
															<td class="custom-table-details" v-else>
																<em class="text-muted">no remark</em>
															</td>
														</tr>
													</td>
												</tr>
											</table>
										</template>
									</div>
								</div>
							</v-tab-item>

							<v-tab-item value="ssl">
								<div class="detail-card bg-white">
									<div
										class="card--header d-flex align-items-center pa-2 border-bottom"
										v-on:click="open_detail = !open_detail"
									>
										<div class="font-level-3-bold">SSL Details</div>
										<v-spacer></v-spacer>
										<v-icon>{{ open_detail ? "mdi-menu-down" : "mdi-menu-right" }}</v-icon>
									</div>
									<div class="card--body d-flex align-items-center pa-2" v-if="open_detail">
										<template v-if="customer_ssl && customer_ssl[0] && customer_ssl[0].type == 'client'">
											<table class="w-100">
												<tr>
													<td class="fw-600 px-3 py-2">Type</td>
													<td class="fw-600 px-3 py-1">
														<tr>
															<td
																class="custom-table-details"
																v-if="customer_ssl && customer_ssl[0] && customer_ssl[0].company_type"
															>
																<span class="text-capitalize">{{ customer_ssl[0].company_type }}</span>
															</td>
														</tr>
													</td>
												</tr>
												<tr>
													<td class="fw-600 px-3 py-2">Remark</td>
													<td class="fw-600 px-3 py-1">
														<tr>
															<td
																class="custom-table-details"
																v-if="customer_ssl && customer_ssl[0] && customer_ssl[0].remark"
															>
																{{ customer_ssl[0].remark }}
															</td>
														</tr>
													</td>
												</tr>
											</table>
										</template>
										<template v-else>
											<table class="w-100">
												<tr>
													<td class="fw-600 px-3 py-2">Type</td>
													<td class="fw-600 px-3 py-1">
														<tr>
															<td
																class="custom-table-details"
																v-if="customer_ssl && customer_ssl[0] && customer_ssl[0].company_type"
															>
																<span class="text-capitalize">{{ customer_ssl[0].company_type }}</span>
															</td>
															<td class="custom-table-details" v-else>
																<em class="text-muted">no remark</em>
															</td>
														</tr>
													</td>
												</tr>
												<tr>
													<td class="fw-600 px-3 py-2">Remark</td>
													<td class="fw-600 px-3 py-1">
														<tr>
															<td
																class="custom-table-details"
																v-if="customer_ssl && customer_ssl[0] && customer_ssl[0].remark"
															>
																{{ customer_ssl[0].remark }}
															</td>
															<td class="custom-table-details" v-else>
																<em class="text-muted">no remark</em>
															</td>
														</tr>
													</td>
												</tr>
											</table>
										</template>
									</div>
								</div>
							</v-tab-item>

							<v-tab-item value="web">
								<div class="detail-card bg-white">
									<div
										class="card--header d-flex align-items-center pa-2 border-bottom"
										v-on:click="open_detail = !open_detail"
									>
										<div class="font-level-3-bold">Web Details</div>
										<v-spacer></v-spacer>
										<v-icon>{{ open_detail ? "mdi-menu-down" : "mdi-menu-right" }}</v-icon>
									</div>
									<div class="card--body d-flex align-items-center pa-2" v-if="open_detail">
										<template v-if="customer_web && customer_web[0] && customer_web[0].type == 'client'">
											<table class="w-100">
												<tr>
													<td class="fw-600 px-3 py-2">Type</td>
													<td class="fw-600 px-3 py-1">
														<tr>
															<td
																class="custom-table-details"
																v-if="customer_web && customer_web[0] && customer_web[0].company_type"
															>
																<span class="text-capitalize">{{ customer_web[0].company_type }}</span>
															</td>
														</tr>
													</td>
												</tr>
												<tr>
													<td class="fw-600 px-3 py-2">Remark</td>
													<td class="fw-600 px-3 py-1">
														<tr>
															<td
																class="custom-table-details"
																v-if="customer_web && customer_web[0] && customer_web[0].remark"
															>
																{{ customer_web[0].remark }}
															</td>
															<td class="custom-table-details" v-else>
																<em class="text-muted">no remark</em>
															</td>
														</tr>
													</td>
												</tr>
											</table>
										</template>
										<template v-else>
											<table class="w-100">
												<tr>
													<td class="fw-600 px-3 py-2">Type</td>
													<td class="fw-600 px-3 py-1">
														<tr>
															<td
																class="custom-table-details"
																v-if="customer_web && customer_web[0] && customer_web[0].company_type"
															>
																<span class="text-capitalize">{{ customer_web[0].company_type }}</span>
															</td>
														</tr>
													</td>
												</tr>
												<tr>
													<td class="fw-600 px-3 py-2">Remark</td>
													<td class="fw-600 px-3 py-1">
														<tr>
															<td
																class="custom-table-details"
																v-if="customer_web && customer_web[0] && customer_web[0].remark"
															>
																{{ customer_web[0].remark }}
															</td>
															<td class="custom-table-details" v-else>
																<em class="text-muted">no remark</em>
															</td>
														</tr>
													</td>
												</tr>
											</table>
										</template>
									</div>
								</div>
							</v-tab-item>

							<v-tab-item value="domain">
								<div class="detail-card bg-white">
									<div
										class="card--header d-flex align-items-center pa-2 border-bottom"
										v-on:click="open_detail = !open_detail"
									>
										<div class="font-level-3-bold">Domain Details</div>
										<v-spacer></v-spacer>
										<v-icon>{{ open_detail ? "mdi-menu-down" : "mdi-menu-right" }}</v-icon>
									</div>
									<div class="card--body d-flex align-items-center pa-2" v-if="open_detail">
										<template v-if="domain && domain[0] && domain[0].type == 'client'">
											<table class="w-100">
												<tr>
													<td class="fw-600 px-3 py-2">Type</td>
													<td class="fw-600 px-3 py-1">
														<tr>
															<td
																class="custom-table-details"
																v-if="domain && domain[0] && domain[0].company_type"
															>
																<span class="text-capitalize">{{ domain[0].company_type }} </span>
															</td>
														</tr>
													</td>
												</tr>
												<tr>
													<td class="fw-600 px-3 py-2">Remark</td>
													<td class="fw-600 px-3 py-1">
														<tr>
															<td class="custom-table-details" v-if="domain && domain[0] && domain[0].remark">
																{{ domain[0].remark }}
															</td>
															<td class="custom-table-details" v-else>
																<em class="text-muted">no remark</em>
															</td>
														</tr>
													</td>
												</tr>
											</table>
										</template>
										<template v-else>
											<table class="w-100">
												<tr>
													<td class="fw-600 px-3 py-2">Type</td>
													<td class="fw-600 px-3 py-1">
														<tr>
															<td
																class="custom-table-details"
																v-if="domain && domain[0] && domain[0].company_type"
															>
																<span class="text-capitalize">{{ domain[0].company_type }}</span>
															</td>
														</tr>
													</td>
												</tr>

												<tr>
													<td class="fw-600 px-3 py-2">Remark</td>
													<td class="fw-600 px-3 py-1">
														<tr>
															<td
																class="custom-table-details"
																style="min-width: 25rem; max-width: 25rem"
																v-if="domain && domain[0] && domain[0].remark"
															>
																{{ domain[0].remark }}
															</td>
															<td class="custom-table-details" v-else>
																<em class="text-muted">no remark</em>
															</td>
														</tr>
													</td>
												</tr>
											</table>
										</template>
									</div>
								</div>
							</v-tab-item>

							<v-tab-item value="maintenance">
								<div class="detail-card bg-white">
									<div
										class="card--header d-flex align-items-center pa-2 border-bottom"
										v-on:click="open_detail = !open_detail"
									>
										<div class="font-level-3-bold">Maintenance Details</div>
										<v-spacer></v-spacer>
										<v-icon>{{ open_detail ? "mdi-menu-down" : "mdi-menu-right" }}</v-icon>
									</div>
									<div class="card--body d-flex align-items-center pa-2" v-if="open_detail">
										<template v-if="maintenance && maintenance[0] && maintenance[0].type == 'client'">
											<table class="w-100">
												<tr>
													<td class="fw-600 px-3 py-2">Type</td>
													<td class="fw-600 px-3 py-1">
														<tr>
															<td
																class="custom-table-details"
																v-if="maintenance && maintenance[0] && maintenance[0].company_type"
															>
																<span class="text-capitalize">{{ maintenance[0].company_type }}</span>
															</td>
														</tr>
													</td>
												</tr>
												<tr>
													<td class="fw-600 px-3 py-2">Remark</td>
													<td class="fw-600 px-3 py-1">
														<tr>
															<td
																class="custom-table-details"
																v-if="maintenance && maintenance[0] && maintenance[0].remark"
															>
																{{ maintenance[0].remark }}
															</td>
															<td class="custom-table-details" v-else>
																<em class="text-muted">no remark</em>
															</td>
														</tr>
													</td>
												</tr>
											</table>
										</template>
										<template v-else>
											<table class="w-100">
												<tr>
													<td class="fw-600 px-3 py-2">Type</td>
													<td class="fw-600 px-3 py-1">
														<tr>
															<td
																class="custom-table-details"
																v-if="maintenance && maintenance[0] && maintenance[0].company_type"
															>
																<span class="text-capitalize">{{ maintenance[0].company_type }}</span>
															</td>
														</tr>
													</td>
												</tr>
												<tr>
													<td class="fw-600 px-3 py-2">Remark</td>
													<td class="fw-600 px-3 py-1">
														<tr>
															<td
																class="custom-table-details"
																v-if="maintenance && maintenance[0] && maintenance[0].remark"
															>
																{{ maintenance[0].remark }}
															</td>
															<td class="custom-table-details" v-else>
																<em class="text-muted">no remark</em>
															</td>
														</tr>
													</td>
												</tr>
											</table>
										</template>
									</div>
								</div>
							</v-tab-item>

							<v-tab-item value="software">
								<div class="detail-card bg-white">
									<!-- <div
										class="card--header d-flex align-items-center pa-2 border-bottom"
									>
										<div class="font-level-3-bold">Software</div>
										<v-spacer></v-spacer>
										<v-icon>{{ open_detail ? "mdi-menu-down" : "mdi-menu-right" }}</v-icon>
									</div> -->
									<div class="card--body pa-2">
										<v-row>
											<v-col md="6">
												<table class="w-100">
													<tr>
														<td class="fw-600 px-3 py-2">Software Type</td>
														<td class="fw-600 px-3 py-1">
															<span class="fw-600" v-if="getValue(customer_software, 'software_type')">
																<v-chip class="mb-1 mr-1" label outlined color="red darken-4">
																	<span>{{
																		mod_string(getValue(customer_software, "software_type"), "_", " ")
																	}}</span>
																</v-chip>
															</span>
															<em class="text-muted" v-else> no software type</em>
														</td>
													</tr>

													<tr>
														<td class="fw-600 px-3 py-2">Tags</td>
														<td class="fw-600 px-3 py-1">
															<template
																v-if="
																	getValue(customer_software, 'tags') && getValue(customer_software, 'tags').length
																"
															>
																<v-chip
																	v-for="tag in getValue(customer_software, 'tags')"
																	:key="tag.id"
																	:color="tag.color"
																	class="mr-1"
																>
																	<span class="white--text">{{ mod_string(tag.tag, "_", " ") }}</span>
																</v-chip>
															</template>
															<template v-else> <em class="text-muted">no tags</em></template>
														</td>
													</tr>

													<tr>
														<td colspan="2">
															<div class="mt-2 form-grp-title d-flex">Sales</div>
														</td>
													</tr>
													<tr>
														<td class="fw-600 px-3 py-2">Sale Date</td>
														<td class="fw-600 px-3 py-1">
															<v-icon left>mdi-calendar</v-icon>
															<template v-if="getValue(customer_software, 'sale_date')">
																{{ formatDate(getValue(customer_software, "sale_date")) }}</template
															>
															<template v-else> <em class="text-muted">no sale date</em></template>
														</td>
													</tr>

													<tr>
														<td class="fw-600 px-3 py-2">Live (Expected)</td>
														<td class="fw-600 px-3 py-1">
															<v-icon left>mdi-calendar</v-icon>
															<template v-if="getValue(customer_software, 'live_expected')">
																{{ formatDate(getValue(customer_software, "live_expected")) }}</template
															>
															<template v-else> <em class="text-muted">no live</em></template>
														</td>
													</tr>

													<tr>
														<td class="fw-600 px-3 py-2">Live (Actual)</td>
														<td class="fw-600 px-3 py-1">
															<v-icon left>mdi-calendar</v-icon>
															<template v-if="getValue(customer_software, 'live_actual')">
																{{ formatDate(getValue(customer_software, "live_actual")) }}</template
															>
															<template v-else> <em class="text-muted">no live</em></template>
														</td>
													</tr>

													<tr>
														<td class="fw-600 px-3 py-2">Contract Period</td>
														<td class="fw-600 px-3 py-1">
															<v-icon left>mdi-update</v-icon>
															<template v-if="getValue(customer_software, 'duration')">
																{{ getValue(customer_software, "duration") }}&nbsp;
																<span class="fw-600">(Month)</span>
															</template>
															<v-icon size="20">mdi-plus</v-icon>
															<template v-if="getValue(customer_software, 'time_free')">
																{{ getValue(customer_software, "time_free") }}&nbsp;
																<span class="fw-600">(Month)</span>
															</template>

															<template v-else> <em class="text-muted">no duration</em></template>
														</td>
													</tr>

													<tr>
														<td colspan="2">
															<div class="mt-2 form-grp-title d-flex">Server</div>
														</td>
													</tr>

													<tr>
														<td class="fw-600 px-3 py-2">Service Provider</td>
														<td class="fw-600 px-3 py-1">
															<v-chip
																label
																v-if="getValue(customer_software, 'service_provider')"
																class="white--text"
																color="green"
															>
																{{ getValue(customer_software, "service_provider") }}
															</v-chip>
															<template v-else> <em class="text-muted">no service provider</em></template>
														</td>
													</tr>

													<tr>
														<td class="fw-600 px-3 py-2">Server</td>
														<td class="fw-600 px-3 py-1">
															<v-icon size="22">mdi-server</v-icon>
															<template v-if="getValue(customer_software, 'server.name')">
																{{ mod_string(getValue(customer_software, "server.name"), "_", " ") }}
															</template>
															<template v-else> <em class="text-muted">no server</em></template>
														</td>
													</tr>

													<tr>
														<td colspan="2">
															<div class="mt-2 form-grp-title d-flex">Support</div>
														</td>
													</tr>

													<tr>
														<td class="fw-600 px-3 py-2">Project Manager (SG)</td>
														<td class="fw-600 px-3 py-1">
															<template v-if="getValue(customer_software, 'project_manager_sg')">
																<v-chip class="mb-1 mr-1" small>
																	<v-avatar left small>
																		<img
																			v-if="getValue(customer_software, 'project_manager_sg.profile_img')"
																			:src="getValue(customer_software, 'project_manager_sg.profile_img')"
																		/>
																		<img
																			v-else
																			src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
																		/>
																	</v-avatar>
																	<span v-if="getValue(customer_software, 'project_manager_sg.display_name')">{{
																		getValue(customer_software, "project_manager_sg.display_name")
																			.charAt(0)
																			.toUpperCase() +
																		getValue(customer_software, "project_manager_sg.display_name").slice(1)
																	}}</span>
																</v-chip>
															</template>
															<template v-else> <em class="text-muted">no project manager</em></template>
														</td>
													</tr>
													<tr>
														<td class="fw-600 px-3 py-2">Primary Support</td>
														<td class="fw-600 px-3 py-1">
															<template v-if="getValue(customer_software, 'primary_support')">
																<v-chip class="mb-1 mr-1" small>
																	<v-avatar left small>
																		<img
																			v-if="getValue(customer_software, 'primary_support.profile_img')"
																			:src="getValue(customer_software, 'primary_support.profile_img')"
																		/>
																		<img
																			v-else
																			src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
																		/>
																	</v-avatar>
																	<span v-if="getValue(customer_software, 'primary_support.display_name')">{{
																		getValue(customer_software, "primary_support.display_name")
																			.charAt(0)
																			.toUpperCase() +
																		getValue(customer_software, "primary_support.display_name").slice(1)
																	}}</span>
																</v-chip>
															</template>

															<template v-else> <em class="text-muted">no primary support</em></template>
														</td>
													</tr>

													<tr>
														<td class="fw-600 px-3 py-2">Secondary Support</td>
														<td class="fw-600 px-3 py-1">
															<template v-if="getValue(customer_software, 'secondary_support')">
																<v-chip class="mb-1 mr-1" small>
																	<v-avatar left small>
																		<img
																			v-if="getValue(customer_software, 'secondary_support.profile_img')"
																			:src="getValue(customer_software, 'secondary_support.profile_img')"
																		/>
																		<img
																			v-else
																			src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
																		/>
																	</v-avatar>
																	<span v-if="getValue(customer_software, 'secondary_support.display_name')">
																		{{
																			getValue(customer_software, "secondary_support.display_name")
																				.charAt(0)
																				.toUpperCase() +
																			getValue(customer_software, "secondary_support.display_name").slice(1)
																		}}</span
																	>
																</v-chip>
															</template>
															<template v-else> <em class="text-muted">no secondary support</em></template>
														</td>
													</tr>
												</table>
											</v-col>

											<v-col md="6">
												<table class="w-100">
													<tr>
														<td colspan="2">
															<div class="form-grp-title d-flex">Recurring</div>
														</td>
													</tr>

													<!-- <tr>
														<td class="text-muted fw-600 px-3 py-2">Recurring Date</td>
														<td class="fw-600 px-3 py-1">
															<v-icon size="22">mdi-server</v-icon>
															<template v-if="getValue(customer_software, 'server')">
																{{ mod_string(getValue(customer_software, "server"), "_", " ") }}
															</template>
															<template v-else> <em class="text-muted">no server</em></template>
														</td>
													</tr> -->

													<tr>
														<td class="fw-600 px-3 py-2">Recurring Date</td>
														<td class="fw-600 px-3 py-1">
															<v-icon left>mdi-calendar</v-icon>
															<template v-if="getValue(customer_software, 'recurring_date')">
																{{ formatDate(getValue(customer_software, "recurring_date")) }}</template
															>
															<template v-else> <em class="text-muted">no recurring date</em></template>
														</td>
													</tr>

													<tr>
														<td class="fw-600 px-3 py-2">Users</td>
														<td class="fw-600 px-3 py-1">
															<v-icon left>mdi-account</v-icon>
															<template v-if="getValue(customer_software, 'no_of_users')">
																{{ getValue(customer_software, "no_of_users") }}</template
															>
															<template v-else> <em class="text-muted">no users</em></template>
														</td>
													</tr>

													<tr>
														<td class="fw-600 px-3 py-2">Total Cost (SGD)</td>
														<td class="fw-600 px-3 py-1">
															<template v-if="getValue(customer_software, 'total_cost')">
																{{ formatMoney(getValue(customer_software, "total_cost")) }}
																<span class="fw-600"
																	>({{ mod_string(getValue(customer_software, "time_interval"), "_", " ") }})</span
																>
															</template>
															<template v-else> <em class="text-muted">no total cost</em></template>
														</td>
													</tr>

													<tr>
														<td class="fw-600 px-3 py-2">Cost Per User (SGD)</td>
														<td class="fw-600 px-3 py-1">
															<template v-if="getValue(customer_software, 'cost_per_user')">
																{{ formatMoney(getValue(customer_software, "cost_per_user")) }}
																<span class="fw-600"
																	>({{ mod_string(getValue(customer_software, "time_interval"), "_", " ") }})</span
																>
															</template>
															<template v-else> <em class="text-muted">no cost per user</em></template>
														</td>
													</tr>

													<tr>
														<td class="fw-600 px-3 py-2">Remark</td>
														<td class="fw-600 px-3 py-1 text-justify" style="max-width: 22rem">
															<template v-if="getValue(customer_software, 'remark')">
																<span v-text="getValue(customer_software, 'remark')"></span>
															</template>
															<template v-else> <em class="text-muted">no remark</em></template>
														</td>
													</tr>

													<template v-if="getValue(customer_software, 'is_grant')">
														<tr>
															<td colspan="2">
																<div class="mt-2 form-grp-title d-flex">Grant</div>
															</td>
														</tr>

														<tr>
															<td class="fw-600 px-3 py-2">Grant Type</td>
															<td class="fw-600 px-3 py-1">
																<v-chip
																	small
																	outlined
																	color="cyan darken-4"
																	label
																	v-if="getValue(customer_software, 'grant_type')"
																>
																	<span class="text-uppercase">
																		{{ getValue(customer_software, "grant_type") }}
																	</span>
																</v-chip>
																<template v-else> <em class="text-muted">no grant type</em></template>
															</td>
														</tr>

														<tr>
															<td class="fw-600 px-3 py-2">LOA Date</td>
															<td class="fw-600 px-3 py-1">
																<v-icon left>mdi-calendar</v-icon>
																<template v-if="getValue(customer_software, 'loa_date')">
																	{{ formatDate(getValue(customer_software, "loa_date")) }}</template
																>
																<template v-else> <em class="text-muted">no loa date</em></template>
															</td>
														</tr>

														<tr>
															<td class="fw-600 px-3 py-2">Filling Date</td>
															<td class="fw-600 px-3 py-1">
																<v-icon left>mdi-calendar</v-icon>
																<template v-if="getValue(customer_software, 'filling_date')">
																	{{ formatDate(getValue(customer_software, "filling_date")) }}</template
																>
																<template v-else> <em class="text-muted">no filling date</em></template>
															</td>
														</tr>

														<tr>
															<td class="fw-600 px-3 py-2">System Live</td>
															<td class="fw-600 px-3 py-1">
																<v-icon left>mdi-calendar</v-icon>
																<template v-if="getValue(customer_software, 'system_live')">
																	{{ formatDate(getValue(customer_software, "system_live")) }}</template
																>
																<template v-else> <em class="text-muted">no system live date</em></template>
															</td>
														</tr>

														<tr>
															<td class="fw-600 px-3 py-2">Claim Deadline</td>
															<td class="fw-600 px-3 py-1">
																<v-icon left>mdi-calendar</v-icon>
																<template v-if="getValue(customer_software, 'claim_deadline')">
																	{{ formatDate(getValue(customer_software, "claim_deadline")) }}</template
																>
																<template v-else> <em class="text-muted">no claim deadline</em></template>
															</td>
														</tr>
													</template>
												</table>
											</v-col>
										</v-row>
									</div>
								</div>
							</v-tab-item>

							<v-tab-item value="quotation-file">
								<div>
									<v-layout class="p-4 border-bottom-light-grey min-height-57px">
										<v-flex class="font-level-3-bold my-auto">
											<span class="detail-svg-icon mr-2">
												<!--begin::Svg Icon-->
												<inline-svg :src="$assetURL('media/custom-svg/files-color.svg')" />
												<!--end::Svg Icon-->
											</span>
											Files
										</v-flex>
										<v-flex class="text-right" v-if="getPermission('contract:create')">
											<v-btn
												v-on:click="uploadDialog"
												color="blue darken-4 text-white"
												class="ml-2"
												depressed
												tile
											>
												<v-icon small left>mdi-playlist-plus</v-icon>
												Attach File
											</v-btn>
										</v-flex>
									</v-layout>
									<div class="overflow-y" style="max-height: calc(100vh - 345px)">
										<table width="100%" class="detail-table table-head-sticky">
											<thead>
												<tr>
													<th class="p-2 light-blue-bg">Quotation #</th>
													<th class="p-2 light-blue-bg">Image</th>
													<th class="p-2 light-blue-bg">Name</th>
													<th class="p-2 light-blue-bg">Action</th>
													<th class="p-2 light-blue-bg">Created By</th>
												</tr>
											</thead>
											<tbody v-if="dbFiles.length">
												<tr v-for="(row, index) in dbFiles" :key="index">
													<td class="p-2 border-top-light-grey">
														<Chip :text="row.barcode" color="cyan"></Chip>
													</td>
													<td class="p-2 border-top-light-grey">
														<template
															v-if="
																getFileExtension(row.url) == 'jpg' ||
																getFileExtension(row.url) == 'jpeg' ||
																getFileExtension(row.url) == 'png' ||
																getFileExtension(row.url) == 'jfif'
															"
														>
															<ImageTemplate style="max-width: 50px; width: 50px" :src="row.url"></ImageTemplate>
														</template>
														<template v-if="getFileExtension(row.url) == 'pdf'">
															<inline-svg
																style="max-width: 50px; width: 50px"
																:src="$assetURL('media/mime/pdf.svg')"
															/>
														</template>
														<template
															v-if="getFileExtension(row.url) == 'docx' || getFileExtension(row.url) == 'doc'"
														>
															<inline-svg
																style="max-width: 50px; width: 50px"
																:src="$assetURL('media/mime/doc.svg')"
															/>
														</template>
														<template
															v-if="getFileExtension(row.url) == 'xls' || getFileExtension(row.url) == 'xlsx'"
														>
															<inline-svg
																style="max-width: 50px; width: 50px"
																:src="$assetURL('media/mime/xls.svg')"
															/>
														</template>
													</td>
													<td class="p-2 border-top-light-grey">
														<p class="m-0 blue--text font-level-1">{{ row.name }}</p>
														<span class="text-muted font-small">{{ formatDate(row.added_at) }} </span>
													</td>
													<!-- <td class="p-2 border-top-light-grey font-level-1" width="50%">
																<v-layout>
																	<v-flex md11> </v-flex>
																	<v-flex md1> {{ getFileSize(row.size) }} KB </v-flex>
																</v-layout>
															</td> -->
													<td class="p-2 border-top-light-grey font-level-1">
														<v-tooltip top>
															<template #activator="{ on, attrs }">
																<v-btn
																	small
																	v-on:click="doActionFile(row, 'download')"
																	icon
																	v-on="on"
																	v-bind="attrs"
																	depressed
																	class="mr-3"
																	style="max-width: 24px !important"
																	color="blue darken-4"
																>
																	<v-icon>mdi-download</v-icon>
																</v-btn>
															</template>
															<span>Download</span>
														</v-tooltip>

														<v-tooltip top>
															<template #activator="{ on, attrs }">
																<v-btn
																	small
																	v-on:click="doActionFile(row, 'delete')"
																	icon
																	v-on="on"
																	v-bind="attrs"
																	depressed
																	color="red lighten-1"
																>
																	<v-icon>mdi-delete</v-icon>
																</v-btn>
															</template>
															<span>Delete</span>
														</v-tooltip>

														<!-- <v-btn small v-on:click="doAction(row, 'delete')" icon depressed color="red lighten-1"
																	><v-icon small>mdi-delete</v-icon></v-btn
																> -->
													</td>
													<td class="p-2 border-top-light-grey font-level-1">
														<v-chip class="mb-1 mr-1" small
															><v-avatar left small>
																<img v-if="row.profile_img" :src="row.profile_img" />
																<img
																	v-else
																	src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
																/>
															</v-avatar>
															<span v-if="row && row.display_name">{{ row.display_name }}</span>
														</v-chip>
													</td>
												</tr>
											</tbody>
											<tfoot v-else>
												<tr>
													<td colspan="4">
														<p class="m-0 row-not-found text-center">
															<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
															Uhh... There are no file at the moment.
														</p>
													</td>
												</tr>
											</tfoot>
										</table>
									</div>
									<Dialog :dialog="deleteDialog" :dialog-width="600">
										<template v-slot:title> Delete File</template>
										<template v-slot:body>
											<v-row class="delete-dialog">
												<v-col md="2" class="py-0 text-right my-auto">
													<span class="svg-icon svg-icon-lg delete-confirm-icon">
														<!--begin::Svg Icon-->
														<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
														<!--end::Svg Icon-->
													</span>
												</v-col>
												<v-col md="10" class="py-0 my-auto">
													<p class="btx-p m-0">Deleting File is irreversible, Are you sure about deleting it?</p>
												</v-col>
											</v-row>
										</template>
										<template v-slot:action>
											<v-btn
												class="white--text"
												:loading="deleteLoading"
												:disabled="deleteLoading"
												depressed
												color="red lighten-1"
												tile
												v-on:click="deleteFile()"
											>
												Yes! Delete
											</v-btn>
											<v-btn depressed tile :disabled="deleteLoading" v-on:click="deleteDialog = false">
												No, Close
											</v-btn>
										</template>
									</Dialog>
								</div>
							</v-tab-item>

							<v-tab-item value="activity">
								<div>
									<div v-for="(logs, index) in logData" :key="index">
										<ActivityTab :open_details="true" :log="logs" title="Contract"></ActivityTab>
									</div>
								</div>
							</v-tab-item>
						</v-tabs-items>
					</div>
				</div>
			</v-layout>
		</div>
		<!-- sidebar drawers -->

		<!-- <ActivityLog
			:drawer="open_dialog.activity_log"
			v-on:close="open_dialog.activity_log = false"
		></ActivityLog> -->
		<CreateContact
			v-if="updateDialog"
			v-on:success="(updateDialog = false), getSingleContract()"
			v-on:refress="(updateDialog = false), getSingleContract()"
			v-on:close="updateDialog = false"
			:update-dialog="updateDialog"
			:contract-id="contractId"
			:customer-id="customerId"
			:settings="moduleSettings ? moduleSettings : []"
		>
		</CreateContact>
		<add-dialog
			:document-dialog="openUploadModel"
			v-on:close="openUploadModel = false"
			v-on:success="getQuotationFile()"
			:related-id="contractId"
			:related-type="'contract'"
		></add-dialog>
	</v-sheet>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Dialog from "@/view/components/Dialog";
import { GET, PATCH } from "@/core/services/store/request.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import ContractSoe from "@/view/module/contract/Contract-Seo-list";
import { toSafeInteger } from "lodash";
import Chip from "@/view/components/Chip";
import CreateContact from "@/view/module/contract/Create-Contract";
import ApiService from "@/core/services/api.service";
import { last } from "lodash";
import ImageTemplate from "@/view/components/Image";
import AddDialog from "@/view/pages/leads/create/AddDialog";
import ActivityTab from "@/view/pages/leads/components/ActivityTab";
import objectPath from "object-path";
import SettingsMixin from "@/core/mixins/settings.mixin.js";
import { getCurrentUser } from "@/core/services/jwt.service";

export default {
	name: "extend-checkout-template",
	title: "Extend Checkout Template",
	mixins: [SettingsMixin],
	props: {
		DetailContractDialog: {
			type: Boolean,
			default: false,
		},
		updatedData: {
			type: Object,
			default() {
				return new Object();
			},
		},
		customerId: {
			type: Number,
			default: 0,
		},
		/* contractId: {
			type: Number,
			default: 0,
		}, */
	},
	data() {
		return {
			settingsEndpoint: "contract-setting",
			deleteDialog: false,
			formValid: true,
			id: 0,
			pageLoading: false,
			logData: [],
			openUploadModel: false,
			contractId: 0,
			deleteLoading: false,
			updateDialog: false,
			/* dataTab: "overview", */
			contractDetail: {},
			open_detail: true,
			dbFiles: [],
			customer_ssl: {},
			salesPersonList: {},
			convert_project: true,
			customer_web: {},
			customer_hosting: {},
			customer_software: {},
			servicesDetails: [],
			maintenance: {},
			domain: {},
		};
	},
	watch: {
		DetailContractDialog(DetailContractDialog) {
			if (DetailContractDialog) {
				this.getSingleContract();
			}
		},
	},
	components: {
		Dialog,
		ImageTemplate,
		ContractSoe,
		Chip,
		CreateContact,
		AddDialog,
		ActivityTab,
	},

	methods: {
		/* getEditUserAllow(currentUser = null) {
			let master = currentUser && currentUser.is_master == 1 ? true : false;

			let type = currentUser && currentUser.users_type;

			let role =
				currentUser &&
				(currentUser.role == 1 ||
					currentUser.role == 4 ||
					currentUser.role == 6 ||
					currentUser.role == 7 ||
					currentUser.role == 12 ||
					currentUser.role == 13)
					? true
					: false;

			return master || type == "accounts" || role ? true : false;
		}, */
		mod_string(text, saperator = "", joiner = "") {
			let splitArr = text ? text.split(saperator) : [];
			let mod_arr = splitArr.map((v) => v.charAt(0).toUpperCase() + v.slice(1));
			return mod_arr.join(joiner);
		},
		hasKey(object, key) {
			return objectPath.has(object, key);
		},
		getValue(object, key) {
			return objectPath.get(object, key);
		},
		getFileExtension(path) {
			if (path) {
				return last(path.split("."));
			}
		},
		copyNumber(data) {
			navigator.clipboard.writeText(data);
			this.$store.commit(SET_MESSAGE, [
				{ model: true, message: `<b>${data}</b> - successfully coppied.` },
			]);
		},
		uploadDialog() {
			this.openUploadModel = true;
		},
		getQuotationFile() {
			this.$store
				.dispatch(GET, {
					url: `contract-file/${this.contractId}`,
				})
				.then((data) => {
					this.dbFiles = data;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		deleteFile() {
			if (!this.id) {
				return false;
			}
			this.deleteLoading = true;
			ApiService.delete(`contract-file/${this.contractId}/${this.id}`)
				.then(() => {
					this.deleteDialog = false;
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! File Deleted successfully." },
					]);
					this.getQuotationFile();
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.deleteLoading = false;
				});
		},
		doActionFile(row, param) {
			switch (param) {
				case "download":
					window.open(row.url, "_blank");
					break;
				case "delete":
					this.id = row.id;
					this.deleteDialog = true;
					break;
			}
		},
		convertProject() {
			const _this = this;
			_this.$router.push({
				name: "create-project",
				query: {
					t: new Date().getTime(),
					contract_id: this.contractId,
					convert_project: this.convert_project,
				},
			});
		},
		taskActivity() {
			this.$store
				.dispatch(GET, {
					url: `contract-log/${this.contractId}`,
				})
				.then((data) => {
					this.logData = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		doAction(status) {
			this.$store
				.dispatch(PATCH, {
					url: `contract-status/${this.contractDetail.id}`,
					data: {
						status: status,
					},
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Contract status updated." },
					]);
					this.getSingleContract();
					this.$emit("updateStatus", true);
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getSingleContract() {
			this.$store
				.dispatch(GET, {
					url: `single-contract/${this.contractId}`,
				})
				.then((data) => {
					this.contractDetail = data.contract;
					(this.salesPersonList = data.attendie[0]), (this.servicesDetails = data.customer_services);
					this.customer_software =
						this.getValue(data, "software") && Array.isArray(data.software) ? data.software[0] : {};
					this.customer_hosting = data.customer_hosting;
					this.customer_web = data.customer_web;
					this.customer_ssl = data.customer_ssl_2;
					this.domain = data.domain_2;
					this.maintenance = data.maintenance;
					this.dataTab = "overview";
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		updateContract(id) {
			this.updateDialog = true;
			this.contractId = id;
			/* this.getSingleContract(); */
		},
	},

	computed: {
		...mapGetters(["errors", "moduleSettings"]),
		dataTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.tab = val;
				if (val != this.dataTab) {
					this.$router.replace({ query });
				}
			},
			get() {
				return this.$route.query.tab || "seo";
			},
		},
	},
	beforeMount() {
		this.contractId = toSafeInteger(this.$route.params.id);
	},
	mounted() {
		if (!this.getPermission("contract:view") && this.contractId > 0) {
			this.$router.replace({
				name: "contract",
				query: { t: new Date().getTime() },
			});
		}
		this.getSingleContract();
		this.getQuotationFile();
		this.taskActivity();
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Contract", disabled: false, href: "/contract" },
			{ text: "Detail", disabled: true },
		]);
		this.currentUser = getCurrentUser();
	},
};
</script>
