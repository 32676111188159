<template>
	<div>
		<div>
			<Dialog
				:dialog="updateDialog"
				:dialog-width="1200"
				@close="cancleCreateContract(), $emit('close', true)"
				:content-class="`${softwareTypeDrawer ? 'd-none' : ''}`"
			>
				<template v-slot:title>
					<span v-if="contractId == 0">Create Contract</span> <span v-else>Update Contract</span>
				</template>

				<template v-slot:body>
					<v-form
						ref="customer_submit"
						v-model.trim="formValid"
						lazy-validation
						v-on:submit.stop.prevent="createOrUpdateCustomer"
					>
						<v-row class="px-4 mt-2 grey lighten-4">
							<v-col>
								<template>
									<v-tabs
										v-model="dataTab"
										background-color="transparent"
										color="blue"
										class="custom-tab-transparent border-bottom w-100"
										active-class="blue darken-4 text-white"
										hide-slider
									>
										<v-tab href="#overviwe"
											><v-icon small left>mdi-information-outline</v-icon>Overview</v-tab
										>
										<v-tab
											href="#seo"
											v-if="contract && contract.checkbox_type && contract.checkbox_type.includes('seo')"
											><v-icon small left>mdi-poll</v-icon>SEO</v-tab
										>
										<v-tab
											href="#hosting"
											v-if="contract && contract.checkbox_type && contract.checkbox_type.includes('hosting')"
											><v-icon small left>mdi-server-network</v-icon>Hosting</v-tab
										>
										<v-tab
											href="#ssl"
											v-if="contract && contract.checkbox_type && contract.checkbox_type.includes('ssl')"
											><v-icon small left>mdi-shield-home</v-icon>SSL</v-tab
										>
										<v-tab
											href="#web"
											v-if="contract && contract.checkbox_type && contract.checkbox_type.includes('web')"
											><v-icon small left>mdi-note</v-icon>Web</v-tab
										>
										<v-tab
											href="#domain"
											v-if="contract && contract.checkbox_type && contract.checkbox_type.includes('domain')"
											><v-icon small left>mdi-information-outline</v-icon>Domain</v-tab
										>
										<v-tab
											href="#maintenance"
											v-if="
												contract && contract.checkbox_type && contract.checkbox_type.includes('maintenance')
											"
										>
											<v-icon small left>mdi-note</v-icon>
											Maintenance
										</v-tab>
										<v-tab
											href="#software"
											v-if="contract && contract.checkbox_type && contract.checkbox_type.includes('software')"
										>
											<v-icon small left>mdi-desktop-classic</v-icon>
											Software
										</v-tab>
									</v-tabs>
								</template>
							</v-col>
						</v-row>
						<div class="pt-2" style="max-height: calc(100vh - 265px) !important; overflow-y: auto">
							<v-tabs-items v-model="dataTab" class="transparent">
								<!-- Overview -->
								<v-tab-item value="overviwe">
									<div class="form-grp-title d-flex">
										<v-row class="px-4">
											<v-col lg="4" cols="12" class="my-auto px-0 required"> Entity *</v-col>

											<v-col lg="8" class="my-auto py-0">
												<v-radio-group
													v-model="contract.entity_type"
													row
													hide-details
													class="mt-0"
													:rules="[vrules.required(contract.entity_type, 'Entity Type')]"
													:class="{
														required: !contract.entity_type,
													}"
												>
													<v-radio value="bthrust" color="#0d47a1"></v-radio>
													<label for="" class="ml-n4 mr-4"
														><img
															style="max-width: 35px; height: 22px"
															:src="$assetURL('media/project-logos/bthrust-icon.png')"
															alt=""
														/>
														Bthrust</label
													>
													<v-radio value="genic-solution" color="#0d47a1"></v-radio>
													<label for="" class="ml-n4 mr-4"
														><img
															style="max-width: 35px; height: 22px"
															:src="$assetURL('media/project-logos/genic.png')"
															alt=""
														/>
														Genic Solution</label
													>
												</v-radio-group>
											</v-col>
										</v-row>
									</div>
									<v-row class="mb-3 px-4" v-if="internalData == false">
										<v-col lg="4" cols="12" class="my-auto py-0">
											<label class="input-label required">Customer</label>
										</v-col>
										<v-col lg="8" class="my-auto py-0">
											<v-autocomplete
												:items="customerData"
												hide-details
												outlined
												:readonly="contractId > 0"
												placeholder="Customer"
												v-model="contract.customer"
												item-text="company_name"
												item-value="id"
												@change="getContractOption($event)"
												:rules="[vrules.required(contract.customer, 'Customer')]"
												:class="{
													required: !contract.customer,
												}"
											>
												<template v-slot:selection="data">
													<span class="text-uppercase">
														{{ data.item.company_name }} ({{ data.item.barcode }})
													</span>
												</template>
												<template v-slot:item="data">
													<template>
														<v-list-item-content style="min-width: 690px !important">
															<v-list-item-title
																>{{ data.item.company_name }}
																<div>
																	<Chip
																		:text="data.item.barcode"
																		color="#24326d"
																		outlined
																		text-color=""
																		v-if="data.item.barcode"
																		class="mr-2"
																	></Chip>
																</div>
															</v-list-item-title>
														</v-list-item-content>
													</template>
												</template>
											</v-autocomplete>
										</v-col>
									</v-row>

									<v-row class="mb-3 px-4">
										<v-col lg="4" cols="12" class="my-auto py-0">
											<label class="input-label required">Title</label>
										</v-col>
										<v-col lg="8" class="my-auto py-0">
											<v-text-field
												hide-details
												outlined
												class="currency-input"
												placeholder="Title"
												v-model="contract.title"
												:rules="[vrules.required(contract.title, 'Title')]"
												:class="{
													required: !contract.title,
												}"
											></v-text-field>
										</v-col>
									</v-row>

									<v-row class="mb-3 px-4">
										<v-col lg="4" cols="12" class="my-auto py-0">
											<label class="input-label">Total Cost</label>
										</v-col>
										<v-col lg="8" class="my-auto py-0">
											<v-text-field
												hide-details
												outlined
												placeholder="Cost"
												type="number"
												min="0"
												max="9999"
												prefix="$"
												v-model.trim.number.lazy="contract.cost"
												@paste="disablePaste"
												@keypress="limitDecimal($event, contract.cost)"
											></v-text-field>
											<!-- :rules="[vrules.required(contract.cost, 'cost')]"
												:class="{
													required: !contract.cost,
												}" -->
										</v-col>
									</v-row>

									<v-row class="mb-3 px-4">
										<v-col lg="4" cols="12" class="my-auto py-0">
											<label class="input-label required">Sales Person</label>
										</v-col>
										<v-col lg="8" class="my-auto py-0">
											<v-autocomplete
												:items="userList"
												item-text="display_name"
												item-value="id"
												depressed
												hide-details
												outlined
												placeholder="Sales Person"
												hide-selected
												class="cuatom-drop"
												multiple
												color="blue darken-4"
												small-chips
												deletable-chips
												v-model="contract.sales_person"
												:disabled="pageLoading"
												:loading="pageLoading"
												:rules="[vrules.required(contract.sales_person, 'Sales Person')]"
												:class="{
													required: !contract.sales_person,
												}"
											>
												<template #selection="data">
													<v-chip
														small
														v-bind="data.attrs"
														:input-value="data.selected"
														close
														@click="data.select"
														@click:close="remove(data.item)"
													>
														<v-avatar v-if="data.item.profile_img" left>
															<img :src="data.item.profile_img" />
															<!-- <img
																v-else
																src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
															/> -->
														</v-avatar>
														{{ data.item.display_name }}
													</v-chip>
												</template>
												<template #item="{ item }">
													<div class="d-flex align-center py-1">
														<v-avatar size="32">
															<img v-if="item.profile_img" :src="item.profile_img" />
															<img
																v-else
																src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
															/>
														</v-avatar>
														<div class="ml-3">
															<div class="fw-500 text-uppercase">{{ item.display_name }}</div>
															<div class="text-muted">{{ item.email }}</div>
														</div>
													</div>
												</template>
												<template #no-data>
													<div class="ml-3 fw-500 py-1 text-muted">No Sales Person available</div>
												</template>
											</v-autocomplete>
										</v-col>
									</v-row>

									<v-row class="mb-3 px-4">
										<v-col lg="4" cols="12" class="my-auto py-0">
											<label class="input-label required">Service Option</label>
										</v-col>
										<v-col cols="12" lg="8">
											<RadioInputCustom
												v-model="contract.service_option"
												:mandatory="false"
												row
												color="blue darken-4"
												:items="[
													{
														label: 'Software',
														value: 'software',
													},
													{
														label: 'Other',
														value: 'other',
													},
												]"
												@change="filterServiceType($event)"
												:rules="[vrules.required(contract.service_option, 'Service Option')]"
												:class="{
													required: !contract.service_option,
												}"
											/>

											<!-- <v-radio-group
												v-model="contract.service_option"
												row
												hide-details
												class="mt-0"
												:rules="[vrules.required(contract.service_option, 'Service Option')]"
												:class="{
													required: !contract.service_option,
												}"
												@change="filterServiceType($event)"
											>
												<div
													class="cursor-pointer d-flex py-1 px-3 mr-3 rounded-sm"
													style="min-width: 148px; border: 1px solid grey"
													@click="contract.service_option = 'software'"
													:style="{
														backgroundColor: contract.service_option == 'software' ? '#0d47a125' : 'transparent',
													}"
												>
													<v-radio :ripple="false" color="#0d47a1" id="service_software" value="software">
														<template #label>
															<label for="service_software" class="mr-4 cursor-pointer"> Software </label>
														</template>
													</v-radio>
												</div>

												<div
													class="cursor-pointer d-flex py-1 px-3 mr-3 rounded-sm"
													style="min-width: 148px; border: 1px solid grey"
													@click="contract.service_option = 'other'"
													:style="{
														backgroundColor: contract.service_option == 'other' ? '#0d47a125' : 'transparent',
													}"
												>
													<v-radio :ripple="false" color="#0d47a1" id="service_other" value="other">
														<template #label>
															<label for="service_other" class="mr-4 cursor-pointer"> Other </label>
														</template>
													</v-radio>
												</div>
											</v-radio-group> -->
										</v-col>
									</v-row>

									<v-row class="mb-3 px-4" v-if="contract.service_option == 'other'">
										<v-col lg="4" cols="12" class="my-auto py-0">
											<label class="input-label required">Type</label>
										</v-col>
										<v-col lg="8" class="my-auto py-0 d-flex">
											<v-flex class="mr-5">
												<v-checkbox
													v-model="contract.checkbox_type"
													label="SEO"
													value="seo"
													hide-details
													v-on:change="hostingFuncyion('seo')"
													class="ma-0"
												></v-checkbox>
											</v-flex>
											<v-flex class="mr-5">
												<v-checkbox
													v-model="contract.checkbox_type"
													label="Hosting"
													value="hosting"
													v-on:change="hostingFuncyion('hosting')"
													hide-details
													class="ma-0"
												></v-checkbox>
											</v-flex>
											<v-flex class="mr-5">
												<v-checkbox
													v-model="contract.checkbox_type"
													label="SSL"
													value="ssl"
													hide-details
													v-on:change="hostingFuncyion('ssl')"
													class="ma-0"
												></v-checkbox>
											</v-flex>
											<v-flex class="mr-5">
												<v-checkbox
													v-model="contract.checkbox_type"
													label="Web"
													value="web"
													hide-details
													v-on:change="hostingFuncyion('web')"
													class="ma-0"
												></v-checkbox>
											</v-flex>
											<v-flex class="mr-5">
												<v-checkbox
													v-model="contract.checkbox_type"
													label="Domain"
													value="domain"
													hide-details
													v-on:change="hostingFuncyion('domain')"
													class="ma-0"
												></v-checkbox>
											</v-flex>
											<v-flex class="mr-5">
												<v-checkbox
													v-model="contract.checkbox_type"
													label="Maintenance"
													value="maintenance"
													hide-details
													v-on:change="hostingFuncyion('maintenance')"
													class="ma-0"
												></v-checkbox>
											</v-flex>
											<v-flex class="mr-5" v-if="false">
												<v-checkbox
													v-model="contract.checkbox_type"
													label="Software"
													value="software"
													hide-details
													v-on:change="hostingFuncyion('software')"
													class="ma-0"
												></v-checkbox>
											</v-flex>
										</v-col>
									</v-row>

									<v-row class="mb-3 px-4">
										<v-col lg="4" cols="12" class="my-auto py-0">
											<label class="input-label">Quotation File </label>
										</v-col>

										<v-col lg="8" class="my-auto py-0">
											<template v-for="(file, index) in files">
												<v-row :key="index">
													<v-col lg="5" cols="12" class="my-auto py-0 d-flex align-items-center">
														<v-file-input
															:id="`document-file-${index}`"
															placeholder="Select File"
															outlined
															class="mt-3"
															prepend-icon=""
															prepend-inner-icon="mdi-attachment"
															hide-details
															v-model="file.file"
															v-on:change="updateFile(index, $event)"
															v-on:click:clear="updateFile(index, $event)"
														></v-file-input>
													</v-col>
													<v-col lg="5" cols="12" class="my-auto py-0 d-flex align-items-center">
														<TextInput
															:id="`document-name-${index}`"
															v-model="file.name"
															hide-details
															placeholder="File Name"
															:suffix="file.suffix"
														></TextInput>
													</v-col>
													<v-col
														lg="2"
														cols="12"
														style="display: grid; grid-template-columns: repeat(2, 1fr)"
														class="pl-0 my-auto py-0"
													>
														<!-- <v-btn
															:disabled="files.length < 2"
															v-on:click="removeFile(index)"
															color="red lighten-1 white--text px-0"
															class="mt-3"
															icon
														> -->
														<v-icon
															:disabled="files.length < 2"
															v-on:click="removeFile(index)"
															color="red lighten-1 white--text px-0"
															class="ml-1 mt-3"
															>mdi-delete
														</v-icon>
														<!-- </v-btn> -->
														<!-- <v-btn
															color="blue darken-4 white--text"
															class="mt-3"
															depressed
															v-on:click="addMore()"
														> -->
														<div>
															<v-icon
																v-if="index == files.length - 1"
																:disabled="files.length > 4"
																color="blue darken-4 white--text"
																:style="{
																	border: `1px solid ${files.length > 4 ? 'rgba(0, 0, 0, 0.38)' : '#0d47a1'}`,
																	borderRadius: '50px',
																}"
																class="ml-3 mt-3"
																depressed
																v-on:click="addMore()"
																>mdi-plus
															</v-icon>
														</div>
														<!-- </v-btn> -->
													</v-col>
													<!-- <v-col lg="1" cols="1" class="my-auto py-0">
													</v-col> -->
												</v-row>
											</template>
										</v-col>
									</v-row>
									<v-row class="mb-3 px-4">
										<v-col lg="4" cols="12" class="my-auto py-0">
											<label class="input-label">Remark </label>
										</v-col>

										<v-col lg="8" class="my-auto py-0">
											<TextAreaInput
												:rows="4"
												dense
												ref="comment"
												hide-details
												v-model="contract.remark"
												placeholder="Enter Remark..."
												:disabled="pageLoading"
												:loading="pageLoading"
												:word-limit="200"
											></TextAreaInput>
										</v-col>
									</v-row>
								</v-tab-item>

								<!-- SEO -->
								<v-tab-item
									value="seo"
									style="max-height: calc(100vh - 300px) !important; overflow-y: auto; overflow-x: hidden"
								>
									<template
										v-if="contract && contract.checkbox_type && contract.checkbox_type.includes('seo')"
									>
										<div class="form-grp-title d-flex">
											<v-row class="px-4">
												<v-col lg="4" cols="12" class="my-auto px-0">SEO</v-col>

												<v-col lg="8" class="my-auto py-0">
													<v-radio-group
														v-model="contract.seo_type"
														row
														hide-details
														v-on:change="resetSeoList($event)"
														class="mt-0"
													>
														<v-radio label="Single" value="single"></v-radio>
														<v-radio label="Combined" value="combined"></v-radio>
													</v-radio-group>
												</v-col>
											</v-row>
										</div>
										<div>
											<template v-for="(data, index) in contract.customer_services">
												<div :key="`index${index}`" class="parent-element">
													<v-row class="mb-3 px-4">
														<v-col lg="4" cols="12" class="my-auto py-0">
															<label class="input-label">Keyword</label>
															<!-- <inline-svg :src="$assetURL(`media/svg/flags/${230-singapore}}.svg`)" /> -->
														</v-col>
														<v-col lg="8" class="my-auto py-0">
															<v-row>
																<v-col cols="4" md="3" class="py-0">
																	<v-text-field
																		v-model="contract.customer_services[index].keyword_monthly"
																		outlined
																		hide-details
																		placeholder="Keyword"
																		type="number"
																		@keypress="allowInput($event, ['number'])"
																		@paste="disablePaste"
																	>
																	</v-text-field>
																</v-col>
																<div class="my-auto">+</div>
																<v-col cols="4" md="3" class="py-0">
																	<v-text-field
																		v-model="contract.customer_services[index].keyword_free"
																		outlined
																		hide-details
																		placeholder="Free"
																		type="number"
																		@keypress="allowInput($event, ['number'])"
																		@paste="disablePaste"
																	>
																	</v-text-field>
																</v-col>
															</v-row>
														</v-col>
													</v-row>
													<v-row class="mb-3 px-4">
														<v-col lg="4" cols="12" class="my-auto py-0">
															<label class="input-label required">Duration </label>
														</v-col>
														<v-col lg="8" class="my-auto py-0">
															<v-row>
																<v-col cols="4" md="3" class="py-0">
																	<v-autocomplete
																		:items="months"
																		item-text="text"
																		item-value="value"
																		hide-details
																		outlined
																		placeholder="Month"
																		v-model="contract.customer_services[index].total_time"
																		:rules="[
																			vrules.required(contract.customer_services[index].total_time, 'Duration'),
																		]"
																		:class="{
																			required: !contract.customer_services[index].total_time,
																		}"
																		@keypress="allowInput($event, ['number'])"
																		@paste="disablePaste"
																	>
																	</v-autocomplete>
																</v-col>
																<div class="my-auto">+</div>
																<v-col cols="4" md="3" class="py-0">
																	<v-text-field
																		v-model="contract.customer_services[index].time_free"
																		outlined
																		hide-details
																		placeholder="Free"
																		@keypress="allowInput($event, ['number'])"
																		@paste="disablePaste"
																	>
																	</v-text-field>
																</v-col>
															</v-row>
														</v-col>
													</v-row>

													<v-row class="mb-3 px-4">
														<v-col lg="4" cols="12" class="my-auto py-0">
															<label class="input-label">Project Manager</label>
														</v-col>
														<v-col lg="8" class="my-auto py-0">
															<!-- project_manager -->
															<v-autocomplete
																:items="usersList"
																item-text="display_name"
																item-value="id"
																hide-details
																outlined
																placeholder="Project Manager"
																v-model="contract.customer_services[index].project_manager"
															>
															</v-autocomplete>
														</v-col>
													</v-row>

													<v-row class="mb-3 px-4">
														<v-col lg="4" cols="12">
															<label class="input-label">Remark </label>
														</v-col>

														<v-col lg="8" class="my-auto py-0">
															<TextAreaInput
																:rows="4"
																dense
																ref="comment"
																:word-limit="200"
																hide-details
																v-model="contract.customer_services[index].remark"
																placeholder="Enter Remark..."
																:disabled="pageLoading"
																:loading="pageLoading"
															></TextAreaInput>
														</v-col>
													</v-row>

													<template v-if="getValue(data, 'customer_urls')">
														<div
															v-for="(child_data, child_index) in data.customer_urls"
															:key="`child_index${child_index}`"
														>
															<v-row class="mb-3 px-4">
																<v-col lg="4" cols="12" class="my-auto py-0">
																	<label class="input-label required">URL</label>
																</v-col>
																<v-col lg="8" class="my-auto py-0">
																	<!-- <v-text-field
																		hide-details
																		outlined
																		placeholder="Urls"
																		v-model="contract.customer_services[index].customer_urls[child_index].website_urls"
																		:rules="[
																			vrules.validUrl(
																				contract.customer_services[index].customer_urls[child_index].website_urls,
																				'Urls'
																			),
																		]"
																		@input="urlInput"
																		:class="{
																			required:
																				!contract.customer_services[index].customer_urls[child_index].website_urls,
																		}"
																	>
																	</v-text-field> -->
																	<v-text-field
																		hide-details
																		outlined
																		placeholder="Urls"
																		v-model="
																			contract.customer_services[index].customer_urls[child_index].website_urls
																		"
																		:rules="[
																			vrules.validUrl(
																				contract.customer_services[index].customer_urls[child_index].website_urls,
																				'URL'
																			) /* urlRules */,
																		]"
																		@input="urlInput"
																		:class="{
																			required:
																				!contract.customer_services[index].customer_urls[child_index].website_urls,
																		}"
																	>
																	</v-text-field>
																</v-col>
															</v-row>
															<v-row class="mb-3 px-4">
																<v-col lg="4" cols="12" class="my-auto py-0">
																	<label class="input-label required">Target Google</label>
																</v-col>
																<v-col lg="8" class="my-auto py-0 position-relative">
																	<v-autocomplete
																		:items="target_list"
																		item-text="text"
																		item-value="id"
																		hide-details
																		outlined
																		placeholder="Target Google"
																		v-model="
																			contract.customer_services[index].customer_urls[child_index].url_target_google
																		"
																		:rules="[
																			vrules.required(
																				contract.customer_services[index].customer_urls[child_index].url_target_google,
																				'Target Google'
																			),
																		]"
																		:class="{
																			required:
																				!contract.customer_services[index].customer_urls[child_index].url_target_google,
																		}"
																	>
																		<template v-slot:selection="data">
																			<!-- <v-avatar left>
																			<inline-svg :src="$assetURL('media/svg/flags/230-singapore.svg')" />
																		</v-avatar> -->
																			<div class="d-flex">
																				<div class="svg-custom-width mr-2">
																					<inline-svg
																						:src="$assetURL(`media/svg/flags/${data.item.status_color}.svg`)"
																					/>
																				</div>
																				<div>
																					{{ data.item.text }}
																				</div>
																			</div>
																		</template>
																		<template v-slot:item="data">
																			<template>
																				<!-- <v-list-item-title>
																			<img :src="data.item.title" />
																		</v-list-item-title> -->
																				<v-list-item-content>
																					<v-list-item-title>
																						<div style="height: 2rem" class="svg-custom-width">
																							<inline-svg
																								:src="$assetURL(`media/svg/flags/${data.item.status_color}.svg`)"
																							/>
																							{{ data.item.text }}
																						</div>
																					</v-list-item-title>
																					<!-- <v-list-item-title v-html="data.item.title"></v-list-item-title> -->
																				</v-list-item-content>
																			</template>
																		</template>
																	</v-autocomplete>
																	<!-- <CreateServer
																	:title="'Target Google'"
																	:type-id="53"customerData
																	v-on:success="getSettings"
																></CreateServer> -->
																</v-col>
															</v-row>
															<template v-if="contract.seo_type == 'combined'">
																<!--  -->
															</template>
															<v-row
																class="mb-3 px-4"
																v-if="
																	contract &&
																	contract.customer_services &&
																	contract.customer_services[0] &&
																	contract.customer_services[0].customer_urls &&
																	contract.customer_services[0].customer_urls.length > 1 &&
																	child_index > 0
																"
															>
																<v-col lg="4" cols="12" class="my-auto py-0">
																	<v-btn
																		tile
																		depressed
																		color="red white--text"
																		v-on:click="
																			removeSeoField(
																				child_index,
																				contract.customer_services[0].customer_urls[child_index].id
																			)
																		"
																	>
																		<v-icon>mdi-delete</v-icon>
																		Remove
																	</v-btn>
																</v-col>
															</v-row>
															<v-col
																cols="12"
																class="pt-0"
																v-if="
																	contract &&
																	contract.customer_services &&
																	contract.customer_services[0] &&
																	contract.customer_services[0].customer_urls &&
																	contract.customer_services[0].customer_urls.length > 1
																"
															>
																<v-divider></v-divider>
															</v-col>
														</div>
													</template>
													<v-row
														class="mb-3 px-4"
														v-if="
															contract &&
															contract.customer_services &&
															contract.customer_services.length > 1 &&
															index > 0
														"
													>
														<v-col lg="4" cols="12" class="my-auto py-0">
															<template v-if="contractId > 0">
																<v-btn
																	tile
																	depressed
																	color="red white--text"
																	v-on:click="deleteServices(contract.customer_services[index].id, index)"
																>
																	<v-icon>mdi-delete</v-icon>
																	Remove
																</v-btn>
															</template>
															<template v-else>
																<v-btn tile depressed color="red white--text" v-on:click="removeSeoField(index)">
																	<v-icon>mdi-delete</v-icon>
																	Remove
																</v-btn>
															</template>
														</v-col>
													</v-row>
													<v-col
														cols="12"
														class="pt-0"
														v-if="contract && contract.customer_services && contract.customer_services.length > 1"
													>
														<v-divider></v-divider>
													</v-col>
												</div>
											</template>

											<v-row class="mb-3 px-4">
												<v-col lg="4" cols="12" class="my-auto py-0">
													<v-btn
														tile
														depressed
														color="blue darken-4 white--text"
														v-on:click="addNresetSeoField()"
													>
														<v-icon>mdi-plus</v-icon>
														Add more
													</v-btn>
												</v-col>
											</v-row>
										</div>
									</template>
								</v-tab-item>

								<!-- Hosting -->
								<v-tab-item value="hosting">
									<template
										v-if="contract && contract.checkbox_type && contract.checkbox_type.includes('hosting')"
									>
										<!-- <div class="form-grp-title d-flex">
										<v-row class="px-4">
											<v-col lg="4" cols="12" class="my-auto px-0"> Hosting</v-col>
											<v-col lg="8" class="my-auto py-0">
												<template
													v-if="contract && contract.hosting && contract.hosting[0] && contract.hosting[0].type"
												>
													<v-radio-group
														v-model="contract.hosting[0].type"
														row
														hide-details
														class="mt-0"
														v-on:change="clearhosting($event)"
													>
														<v-radio label="Client" value="client"></v-radio>
														<v-radio label="Bthrust" value="bthrust"></v-radio>
													</v-radio-group>
												</template>
											</v-col>
										</v-row>
									</div> -->
										<template
											v-if="
												contract &&
												contract.hosting &&
												contract.hosting[0] &&
												contract.hosting[0].type &&
												contract.hosting[0].type == 'client'
											"
										>
											<v-row class="mb-3 px-4">
												<v-col lg="4" cols="12">
													<label class="input-label">Remark </label>
												</v-col>

												<v-col lg="8" class="my-auto py-0">
													<TextAreaInput
														:rows="4"
														dense
														ref="comment"
														:word-limit="200"
														hide-details
														v-model="contract.hosting[0].remark"
														placeholder="Enter Remark..."
														:disabled="pageLoading"
														:loading="pageLoading"
													></TextAreaInput>
												</v-col>
											</v-row>
										</template>
										<template
											v-if="
												contract &&
												contract.hosting &&
												contract.hosting[0] &&
												contract.hosting[0].type &&
												contract.hosting[0].type == 'bthrust'
											"
										>
											<v-row class="mb-3 px-4">
												<v-col lg="4" cols="12">
													<label class="input-label">Remark </label>
												</v-col>

												<v-col lg="8" class="my-auto py-0">
													<TextAreaInput
														:rows="4"
														dense
														ref="comment"
														:word-limit="200"
														hide-details
														v-model="contract.hosting[0].remark"
														placeholder="Enter Remark..."
														:disabled="pageLoading"
														:loading="pageLoading"
													></TextAreaInput>
												</v-col>
											</v-row>
										</template>
									</template>
								</v-tab-item>

								<!-- SSL -->
								<v-tab-item value="ssl">
									<template
										v-if="contract && contract.checkbox_type && contract.checkbox_type.includes('ssl')"
									>
										<!-- <div class="form-grp-title d-flex">
										<v-row class="px-4">
											<v-col lg="4" cols="12" class="my-auto px-0"> SSL </v-col>
											<v-col lg="8" class="my-auto py-0">
												<template v-if="contract && contract.ssl && contract.ssl[0] && contract.ssl[0].type">
													<v-radio-group
														v-model="contract.ssl[0].type"
														row
														hide-details
														class="mt-0"
														v-on:change="clearSsl($event)"
													>
														<v-radio label="Client" value="client"></v-radio>
														<v-radio label="Bthrust" value="bthrust"></v-radio>
													</v-radio-group>
												</template>
											</v-col>
										</v-row>
									</div> -->
										<!-- <div class="form-grp-title d-flex">
										<v-row class="px-4">
											<v-col lg="4" cols="12" class="my-auto px-0"> Entity</v-col>

											<v-col lg="8" class="my-auto py-0">
												<v-radio-group v-model="contract.ssl[0].company_type" row hide-details class="mt-0">
													<v-radio value="bthrust"></v-radio>
													<label for="" class="ml-n4 mr-4"
														><img
															style="max-width: 35px; height: 22px"
															:src="$assetURL('media/project-logos/bthrust-icon.png')"
															alt=""
														/>
														Bthrust</label
													>
													<v-radio value="genic-solution"></v-radio>
													<label for="" class="ml-n4 mr-4"
														><img
															style="max-width: 35px; height: 22px"
															:src="$assetURL('media/project-logos/genic.png')"
															alt=""
														/>
														Genic Solution</label
													>
												</v-radio-group>
											</v-col>
										</v-row>
									</div> -->
										<template
											v-if="
												contract.ssl &&
												contract.ssl[0] &&
												contract.ssl[0].type &&
												contract.ssl[0].type == 'client'
											"
										>
											<v-row class="mb-3 px-4">
												<v-col lg="4" cols="12">
													<label class="input-label">Remark </label>
												</v-col>

												<v-col lg="8" class="my-auto py-0">
													<TextAreaInput
														:rows="4"
														dense
														ref="comment"
														:word-limit="200"
														hide-details
														v-model="contract.ssl[0].remark"
														placeholder="Enter Remark..."
														:disabled="pageLoading"
														:loading="pageLoading"
													></TextAreaInput>
												</v-col>
											</v-row>
										</template>
										<template
											v-if="
												contract &&
												contract.ssl &&
												contract.ssl[0] &&
												contract.ssl[0].type &&
												contract.ssl[0].type == 'bthrust'
											"
										>
											<v-row class="mb-3 px-4">
												<v-col lg="4" cols="12">
													<label class="input-label">Remark </label>
												</v-col>

												<v-col lg="8" class="my-auto py-0">
													<TextAreaInput
														:rows="4"
														dense
														ref="comment"
														:word-limit="200"
														hide-details
														v-model="contract.ssl[0].remark"
														placeholder="Enter Remark..."
														:disabled="pageLoading"
														:loading="pageLoading"
													></TextAreaInput>
												</v-col>
											</v-row>
										</template>
									</template>
								</v-tab-item>

								<!-- WEB -->
								<v-tab-item value="web">
									<template
										v-if="contract && contract.checkbox_type && contract.checkbox_type.includes('web')"
									>
										<!-- <div class="form-grp-title d-flex">
										<v-row class="px-4">
											<v-col lg="4" cols="12" class="my-auto px-0"> Web </v-col>
											<v-col lg="8" class="my-auto py-0">
												<template v-if="contract && contract.web && contract.web[0] && contract.web[0].type">
													<v-radio-group
														v-model="contract.web[0].type"
														row
														hide-details
														class="mt-0"
														v-on:change="clearWeb($event)"
													>
														<v-radio label="Client" value="client"></v-radio>
														<v-radio label="Bthrust" value="bthrust"></v-radio>
													</v-radio-group>
												</template>
											</v-col>
										</v-row>
									</div> -->
										<!-- <div class="form-grp-title d-flex">
										<v-row class="px-4">
											<v-col lg="4" cols="12" class="my-auto px-0"> Entity</v-col>

											<v-col lg="8" class="my-auto py-0">
												<v-radio-group v-model="contract.web[0].company_type" row hide-details class="mt-0">
													<v-radio value="bthrust"></v-radio>
													<label for="" class="ml-n4 mr-4"
														><img
															style="max-width: 35px; height: 22px"
															:src="$assetURL('media/project-logos/bthrust-icon.png')"
															alt=""
														/>
														Bthrust</label
													>
													<v-radio value="genic-solution"></v-radio>
													<label for="" class="ml-n4 mr-4"
														><img
															style="max-width: 35px; height: 22px"
															:src="$assetURL('media/project-logos/genic.png')"
															alt=""
														/>
														Genic Solution</label
													>
												</v-radio-group>
											</v-col>
										</v-row>
									</div> -->
										<template
											v-if="
												contract.web &&
												contract.web[0] &&
												contract.web[0].type &&
												contract.web[0].type == 'client'
											"
										>
											<v-row class="mb-3 px-4">
												<v-col lg="4" cols="12">
													<label class="input-label">Remark </label>
												</v-col>

												<v-col lg="8" class="my-auto py-0">
													<TextAreaInput
														:rows="4"
														dense
														ref="comment"
														:word-limit="200"
														hide-details
														v-model="contract.web[0].remark"
														placeholder="Enter Remark..."
														:disabled="pageLoading"
														:loading="pageLoading"
													></TextAreaInput>
												</v-col>
											</v-row>
										</template>
										<template
											v-if="
												contract.web &&
												contract.web[0] &&
												contract.web[0].type &&
												contract.web[0].type == 'bthrust'
											"
										>
											<v-row class="mb-3 px-4">
												<v-col lg="4" cols="12">
													<label class="input-label">Remark </label>
												</v-col>

												<v-col lg="8" class="my-auto py-0">
													<TextAreaInput
														:rows="4"
														dense
														ref="comment"
														:word-limit="200"
														hide-details
														v-model="contract.web[0].remark"
														placeholder="Enter Remark..."
														:disabled="pageLoading"
														:loading="pageLoading"
													></TextAreaInput>
												</v-col>
											</v-row>
										</template>
									</template>
								</v-tab-item>

								<!-- Domain -->
								<v-tab-item value="domain">
									<template
										v-if="contract && contract.checkbox_type && contract.checkbox_type.includes('domain')"
									>
										<!-- <div class="form-grp-title d-flex">
										<v-row class="px-4">
											<v-col lg="4" cols="12" class="my-auto px-0"> Domain</v-col>
											<v-col lg="8" class="my-auto py-0">
												<template
													v-if="contract && contract.domain && contract.domain[0] && contract.domain[0].type"
												>
													<v-radio-group
														v-model="contract.domain[0].type"
														row
														hide-details
														class="mt-0"
														v-on:change="clearDomain($event)"
													>
														<v-radio label="Client" value="client"></v-radio>
														<v-radio label="Bthrust" value="bthrust"></v-radio>
													</v-radio-group>
												</template>
											</v-col>
										</v-row>
									</div> -->
										<!-- <div class="form-grp-title d-flex">
										<v-row class="px-4">
											<v-col lg="4" cols="12" class="my-auto px-0"> Entity</v-col>

											<v-col lg="8" class="my-auto py-0">
												<v-radio-group v-model="contract.domain[0].company_type" row hide-details class="mt-0">
													<v-radio value="bthrust"></v-radio>
													<label for="" class="ml-n4 mr-4"
														><img
															style="max-width: 35px; height: 22px"
															:src="$assetURL('media/project-logos/bthrust-icon.png')"
															alt=""
														/>
														Bthrust</label
													>
													<v-radio value="genic-solution"></v-radio>
													<label for="" class="ml-n4 mr-4"
														><img
															style="max-width: 35px; height: 22px"
															:src="$assetURL('media/project-logos/genic.png')"
															alt=""
														/>
														Genic Solution</label
													>
												</v-radio-group>
											</v-col>
										</v-row>
									</div> -->
										<template
											v-if="
												contract &&
												contract.domain[0] &&
												contract.domain[0].type &&
												contract.domain[0].type == 'client'
											"
										>
											<v-row class="mb-3 px-4">
												<v-col lg="4" cols="12">
													<label class="input-label">Remark </label>
												</v-col>

												<v-col lg="8" class="my-auto py-0">
													<TextAreaInput
														:rows="4"
														dense
														ref="comment"
														:word-limit="200"
														hide-details
														v-model="contract.domain[0].remark"
														placeholder="Enter Remark..."
														:disabled="pageLoading"
														:loading="pageLoading"
													></TextAreaInput>
												</v-col>
											</v-row>
										</template>
										<template
											v-if="
												contract.domain &&
												contract.domain[0] &&
												contract.domain[0].type &&
												contract.domain[0].type == 'bthrust'
											"
										>
											<v-row class="mb-3 px-4">
												<v-col lg="4" cols="12">
													<label class="input-label">Remark </label>
												</v-col>

												<v-col lg="8" class="my-auto py-0">
													<TextAreaInput
														:rows="4"
														dense
														ref="comment"
														hide-details
														:word-limit="200"
														v-model="contract.domain[0].remark"
														placeholder="Enter Remark..."
														:disabled="pageLoading"
														:loading="pageLoading"
													></TextAreaInput>
												</v-col>
											</v-row>
										</template>
									</template>
								</v-tab-item>

								<!-- Maintainance -->
								<v-tab-item value="maintenance">
									<template
										v-if="
											contract && contract.checkbox_type && contract.checkbox_type.includes('maintenance')
										"
									>
										<!-- <div class="form-grp-title d-flex">
										<v-row class="px-4">
											<v-col lg="4" cols="12" class="my-auto px-0"> Maintenance</v-col>
											<v-col lg="8" class="my-auto py-0">
												<template
													v-if="
														contract &&
														contract.maintenance &&
														contract.maintenance[0] &&
														contract.maintenance[0].type
													"
												>
													<v-radio-group
														v-model="contract.maintenance[0].type"
														row
														hide-details
														class="mt-0"
														v-on:change="clearMaintenece($event)"
													>
														<v-radio label="Client" value="client"></v-radio>
														<v-radio label="Bthrust" value="bthrust"></v-radio>
													</v-radio-group>
												</template>
											</v-col>
										</v-row>
									</div> -->
										<!-- <div class="form-grp-title d-flex">
										<v-row class="px-4">
											<v-col lg="4" cols="12" class="my-auto px-0"> Entity</v-col>

											<v-col lg="8" class="my-auto py-0">
												<v-radio-group
													v-model="contract.maintenance[0].company_type"
													row
													hide-details
													class="mt-0"
												>
													<v-radio value="bthrust"></v-radio>
													<label for="" class="ml-n4 mr-4"
														><img
															style="max-width: 35px; height: 22px"
															:src="$assetURL('media/project-logos/bthrust-icon.png')"
															alt=""
														/>
														Bthrust</label
													>
													<v-radio value="genic-solution"></v-radio>
													<label for="" class="ml-n4 mr-4"
														><img
															style="max-width: 35px; height: 22px"
															:src="$assetURL('media/project-logos/genic.png')"
															alt=""
														/>
														Genic Solution</label
													>
												</v-radio-group>
											</v-col>
										</v-row>
									</div> -->

										<template
											v-if="contract && contract.maintenance[0] && contract.maintenance[0].type == 'client'"
										>
											<v-row class="mb-3 px-4">
												<v-col lg="4" cols="12">
													<label class="input-label">Remark</label>
												</v-col>
												<v-col lg="8" class="my-auto py-0">
													<TextAreaInput
														:rows="4"
														dense
														ref="comment"
														:word-limit="200"
														hide-details
														v-model="contract.maintenance[0].remark"
														placeholder="Enter Remark..."
														:disabled="pageLoading"
														:loading="pageLoading"
													></TextAreaInput>
												</v-col>
											</v-row>
										</template>

										<template
											v-if="
												contract.maintenance &&
												contract.maintenance[0] &&
												contract.maintenance[0].type &&
												contract.maintenance[0].type == 'bthrust'
											"
										>
											<v-row class="mb-3 px-4">
												<v-col lg="4" cols="12">
													<label class="input-label">Remark</label>
												</v-col>
												<v-col lg="8" class="my-auto py-0">
													<TextAreaInput
														:rows="4"
														dense
														ref="comment"
														:word-limit="200"
														hide-details
														v-model="contract.maintenance[0].remark"
														placeholder="Enter Remark..."
														:disabled="pageLoading"
														:loading="pageLoading"
													></TextAreaInput>
												</v-col>
											</v-row>
										</template>
									</template>
								</v-tab-item>

								<v-tab-item value="software">
									<template
										v-if="
											getValue(contract, 'checkbox_type') &&
											getValue(contract, 'checkbox_type').length &&
											getValue(contract, 'checkbox_type').includes('software')
										"
									>
										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label required">Software Type</label>
											</v-col>
											<v-col lg="8" class="my-auto py-0 pr-10">
												<v-autocomplete
													hide-details
													outlined
													placeholder="Software Type"
													:items.sync="software_type_list"
													item-text="name"
													item-value="value"
													v-model="contract.software[0].software_type"
													:rules="[vrules.required(contract.software[0].software_type, 'Software Type')]"
													:class="{
														required: !contract.software[0].software_type,
													}"
												>
												</v-autocomplete>
												<v-icon
													link
													class="input-addon"
													:color="'primary'"
													v-on:click="softwareTypeDrawer = true"
												>
													mdi-cog
												</v-icon>
											</v-col>
										</v-row>

										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Tags</label>
											</v-col>
											<v-col lg="8" class="my-auto py-0">
												<TagsAutoSearch type="delivery" v-model="contract.software[0].tags" />
											</v-col>
										</v-row>

										<div class="form-grp-title d-flex">Sales</div>

										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label required">Sale Date</label>
											</v-col>
											<v-col lg="8" class="my-auto py-0">
												<DatePicker
													hide-details
													clearable
													hideTopMargin
													:disabled="pageLoading"
													:loading="pageLoading"
													id="sale-date"
													placeholder="Sale Date"
													v-model="contract.software[0].sale_date"
													:rules="[vrules.required(contract.software[0].sale_date, 'Sale Date')]"
													:class="{
														required: !contract.software[0].sale_date,
													}"
												>
												</DatePicker>
											</v-col>
										</v-row>

										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Live (Expected)</label>
											</v-col>
											<v-col lg="8" class="my-auto py-0">
												<DatePicker
													hide-details
													clearable
													hideTopMargin
													:disabled="pageLoading"
													:loading="pageLoading"
													id="contract-date"
													placeholder="Expected (Live Date)"
													v-model="contract.software[0].live_expected"
												>
												</DatePicker>
											</v-col>
										</v-row>

										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Live (Actual)</label>
											</v-col>
											<v-col lg="8" class="my-auto py-0">
												<DatePicker
													hide-details
													clearable
													hideTopMargin
													:disabled="pageLoading"
													:loading="pageLoading"
													id="actual-live-date"
													placeholder="Actual (Live Date)"
													v-model="contract.software[0].live_actual"
													@change="dateFormated(contract.software[0], 0, 'software')"
												>
												</DatePicker>
											</v-col>
										</v-row>

										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label required">Contract Period</label>
											</v-col>
											<v-col lg="8" class="my-auto py-0">
												<v-row>
													<v-col cols="4" md="6" class="py-0">
														<v-autocomplete
															:items="months"
															item-text="text"
															item-value="value"
															hide-details
															outlined
															placeholder="Months"
															v-model="contract.software[0].duration"
															:rules="[vrules.required(contract.software[0].duration, 'Months')]"
															@change="dateFormated(contract.software[0], 0, 'software')"
															:class="{
																required: !contract.software[0].duration,
															}"
															@keypress="allowInput($event, ['number'])"
															@paste="disablePaste"
														>
														</v-autocomplete>
													</v-col>
													<div class="my-auto">+</div>
													<v-col cols="4" md="5" class="py-0 extend-picker">
														<NumberInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="time-free"
															placeholder="Free"
															hideTopMargin
															v-model="contract.software[0].time_free"
															@input="dateFormated(contract.software[0], 0, 'software')"
														></NumberInput>
													</v-col>
												</v-row>
											</v-col>
										</v-row>

										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Is Grant</label>
											</v-col>
											<v-col lg="8" class="my-auto py-0">
												<v-checkbox
													:false-value="false"
													:true-value="true"
													hide-details
													class="m-0 p-0"
													:ripple="false"
													v-model="contract.software[0].is_grant"
													@change="changeGrant"
												></v-checkbox>
											</v-col>
										</v-row>

										<template v-if="getValue(contract, 'software.0.is_grant')">
											<div class="form-grp-title d-flex">Grant</div>
											<v-row class="mb-3 px-4">
												<v-col lg="4" cols="12" class="my-auto py-0"></v-col>

												<v-col lg="8" class="my-auto py-0">
													<v-radio-group
														class="mt-0"
														row
														hide-details
														dense
														v-model="contract.software[0].grant_type"
													>
														<v-radio
															v-for="p in [
																{ title: 'EDG', value: 'edg' },
																{ title: 'PSG', value: 'psg' },
															]"
															:key="p.title + p.value"
															:label="p.title"
															:value="p.value"
														>
														</v-radio>
													</v-radio-group>
												</v-col>
											</v-row>

											<v-row class="mb-3 px-4">
												<v-col lg="4" cols="12" class="my-auto py-0">
													<label class="input-label">LOA Date</label>
												</v-col>

												<v-col lg="8" class="my-auto py-0">
													<DatePicker
														hide-details
														clearable
														hideTopMargin
														:disabled="pageLoading"
														:loading="pageLoading"
														id="loa-date"
														placeholder="LOA Date"
														v-model="contract.software[0].loa_date"
													>
													</DatePicker>
												</v-col>
											</v-row>

											<v-row class="mb-3 px-4">
												<v-col lg="4" cols="12" class="my-auto py-0">
													<label class="input-label">Grant Filling Date</label>
												</v-col>

												<v-col lg="8" class="my-auto py-0">
													<DatePicker
														hide-details
														clearable
														hideTopMargin
														:disabled="pageLoading"
														:loading="pageLoading"
														id="filling-date"
														placeholder="Grant Filling Date"
														v-model="contract.software[0].filling_date"
													>
													</DatePicker>
												</v-col>
											</v-row>

											<v-row class="mb-3 px-4">
												<v-col lg="4" cols="12" class="my-auto py-0">
													<label class="input-label">System Live</label>
												</v-col>

												<v-col lg="8" class="my-auto py-0">
													<DatePicker
														hide-details
														clearable
														hideTopMargin
														:disabled="pageLoading"
														:loading="pageLoading"
														id="system-live"
														placeholder="System Live"
														v-model="contract.software[0].system_live"
													>
													</DatePicker>
												</v-col>
											</v-row>

											<v-row class="mb-3 px-4">
												<v-col lg="4" cols="12" class="my-auto py-0">
													<label class="input-label">Claim Deadline</label>
												</v-col>

												<v-col lg="8" class="my-auto py-0">
													<DatePicker
														hide-details
														clearable
														hideTopMargin
														:disabled="pageLoading"
														:loading="pageLoading"
														id="claim-deadline"
														placeholder="Claim Deadline"
														v-model="contract.software[0].claim_deadline"
													>
													</DatePicker>
												</v-col>
											</v-row>
										</template>

										<div class="form-grp-title d-flex">Server</div>

										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Service Provider</label>
											</v-col>
											<v-col lg="8" class="my-auto py-0 position-relative">
												<v-autocomplete
													:items="serverProvider"
													item-text="text"
													item-value="value"
													hide-details
													outlined
													placeholder="Service Provider"
													v-model="contract.software[0].service_provider"
												>
												</v-autocomplete>
											</v-col>
										</v-row>

										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Server</label>
											</v-col>

											<v-col lg="8" class="my-auto py-0">
												<v-autocomplete
													hide-details
													outlined
													:items.sync="serverWeb"
													placeholder="Server"
													item-text="name"
													item-value="id"
													v-model="contract.software[0].server"
												>
												</v-autocomplete>
												<!-- <v-tooltip right>
													<template #activator="{ on, attrs }">
														<v-icon
															link
															v-on="on"
															v-bind="attrs"
															class="input-addon"
															:color="'primary'"
															v-on:click="serverTypeDrawer = true"
														>
															mdi-cog
														</v-icon>
													</template>
													<span>Server Settings</span>
												</v-tooltip> -->
											</v-col>
										</v-row>

										<div class="form-grp-title d-flex">Recurring</div>

										<!-- <v-row class="mb-3 px-4">
												<v-col lg="4" cols="12" class="my-auto py-0">
													<label class="input-label required">Expected Live Date</label>
												</v-col>
												<v-col lg="8" class="my-auto py-0">
													<DatePicker
														hide-details
														clearable
														hideTopMargin
														:disabled="pageLoading"
														:loading="pageLoading"
														id="contract-date"
														placeholder="Expected Live Date"
														v-model="project.software[0].live_expected"
														:rules="vrules.required(project.software[0].live_expected, 'Expected Live Date')"
													>
													</DatePicker>
												</v-col>
											</v-row> -->

										<!-- <v-row class="mb-3 px-4">
												<v-col lg="4" cols="12" class="my-auto py-0">
													<label class="input-label">Contract Period</label>
												</v-col>
												<v-col lg="8" class="my-auto py-0">
													<v-row>
														<v-col cols="4" md="6" class="py-0">
															<v-autocomplete
																:items="months"
																item-text="text"
																item-value="value"
																hide-details
																outlined
																placeholder="Months"
																v-model="project.software[0].duration"
																:rules="vrules.required(project.software[0].duration, 'Months')"
															>
															</v-autocomplete>
														</v-col>
														<div class="my-auto">+</div>
														<v-col cols="4" md="5" class="py-0 extend-picker">
															<v-text-field
																outlined
																hide-details
																placeholder="Free"
																v-model="project.software[0].time_free"
															>
															</v-text-field>
														</v-col>
													</v-row>
												</v-col>
											</v-row> -->

										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Start Date</label>
											</v-col>

											<v-col lg="8" class="my-auto py-0">
												<!-- readonly -->
												<DatePicker
													hide-details
													clearable
													hideTopMargin
													:disabled="pageLoading"
													:loading="pageLoading"
													id="contract-date"
													placeholder="Start Date"
													v-model="contract.software[0].recurring_date"
												>
												</DatePicker>
											</v-col>
										</v-row>

										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0"> Users </v-col>

											<v-col lg="8" class="my-auto py-0">
												<!-- <NumberInput
													hide-details
													:disabled="pageLoading"
													:loading="pageLoading"
													:id="`checkout-duration`"
													placeholder="No of Users"
													hideTopMargin
													v-model="contract.software[0].no_of_users"
													@keyup="calculateUsersAndCost($event.target.value, 'users')"
													@keypress="allowInput($event, ['number'])"
													@paste="disablePaste"
												></NumberInput> -->

												<v-text-field
													type="number"
													outlined
													hide-details
													:disabled="pageLoading"
													:loading="pageLoading"
													:id="`checkout-duration`"
													placeholder="No of Users (Max: 99999)"
													prependInnerIcon="mdi-account"
													v-model="contract.software[0].no_of_users"
													@input="calculateUsersAndCost($event, 'users')"
													@keypress="
														allowInput($event, ['number']), maxValidation(contract.software[0].no_of_users)
													"
													@paste="disablePaste"
												>
												</v-text-field>
											</v-col>
										</v-row>

										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">Total Cost (SGD) </v-col>

											<v-col lg="8" class="my-auto py-0">
												<v-row>
													<v-col md="6" class="py-0">
														<!-- <NumberInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															:id="`checkout-duration`"
															placeholder="Cost"
															prependInnerIcon="mdi-currency-usd"
															hideTopMargin
															v-model="contract.software[0].total_cost"
															@keyup="calculateUsersAndCost($event.target.value, 'total')"
														></NumberInput> -->
														<v-text-field
															type="number"
															outlined
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															:id="`checkout-duration`"
															placeholder="Cost"
															prependInnerIcon="mdi-currency-usd"
															v-model="contract.software[0].total_cost"
															@input="calculateUsersAndCost($event, 'total')"
															@paste="disablePaste"
															@keypress="limitDecimal($event, contract.software[0].total_cost)"
														>
														</v-text-field>
													</v-col>
													<div class="my-auto">/</div>
													<v-col md="5" class="py-0 extend-picker">
														<SelectInput
															hide-details
															:items="[
																{ title: 'Monthly', value: 'monthly' },
																{ title: 'Yearly', value: 'yearly' },
															]"
															:disabled="pageLoading"
															customClass=""
															:loading="pageLoading"
															placeholder="Duration"
															item-text="title"
															item-value="value"
															v-model="contract.software[0].time_interval"
														></SelectInput>
													</v-col>
												</v-row>
											</v-col>
										</v-row>

										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Cost Per User (SGD) </label>
											</v-col>

											<v-col lg="8" class="my-auto py-0">
												<v-row>
													<v-col md="6" class="py-0">
														<!-- <NumberInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															:id="`checkout-duration`"
															placeholder="Cost"
															prependInnerIcon="mdi-currency-usd"
															hideTopMargin
															v-model="contract.software[0].cost_per_user"
															@keyup="calculateUsersAndCost($event.target.value, 'cost')"
														></NumberInput> -->
														<v-text-field
															type="number"
															outlined
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															:id="`checkout-duration`"
															placeholder="Cost"
															prependInnerIcon="mdi-currency-usd"
															v-model="contract.software[0].cost_per_user"
															@input="calculateUsersAndCost($event, 'cost')"
															@paste="disablePaste"
															@keypress="limitDecimal($event, contract.software[0].cost_per_user)"
														>
														</v-text-field>
													</v-col>
													<div class="my-auto">/</div>
													<v-col md="5" class="py-0 extend-picker">
														<SelectInput
															hide-details
															:items="[
																{ title: 'Monthly', value: 'monthly' },
																{ title: 'Yearly', value: 'yearly' },
															]"
															:disabled="pageLoading"
															customClass=""
															:loading="pageLoading"
															placeholder="Duration"
															item-text="title"
															item-value="value"
															v-model="contract.software[0].time_interval"
														></SelectInput>
													</v-col>
												</v-row>
											</v-col>
										</v-row>

										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Remark </label>
											</v-col>

											<v-col lg="8" class="my-auto py-0">
												<TextAreaInput
													:rows="4"
													dense
													ref="comment"
													:word-limit="200"
													hide-details
													placeholder="Enter Remark..."
													:disabled="pageLoading"
													:loading="pageLoading"
													v-model="contract.software[0].remark"
												></TextAreaInput>
											</v-col>
										</v-row>

										<div class="form-grp-title d-flex">Support</div>

										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Project Manager (SG)</label>
											</v-col>

											<v-col lg="8" class="my-auto py-0">
												<v-autocomplete
													hide-details
													outlined
													:items="usersList"
													placeholder="Project Manager (SG)"
													item-text="display_name"
													item-value="id"
													v-model="contract.software[0].project_manager_sg"
													@input="support_user_select($event, 'primary')"
												>
													<!-- :rules="[vrules.required(contract.customer, 'Customer')]" -->
													<!-- :class="{
															required: !contract.customer,
														}" -->
												</v-autocomplete>
											</v-col>
										</v-row>

										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Primary Tech</label>
											</v-col>

											<v-col lg="8" class="my-auto py-0">
												<v-autocomplete
													hide-details
													outlined
													:items="primary_support_users"
													placeholder="Primary Tech Support"
													item-text="display_name"
													item-value="id"
													v-model="contract.software[0].primary_support"
													@input="support_user_select($event, 'primary')"
												>
													<!-- :rules="[vrules.required(contract.customer, 'Customer')]" -->
													<!-- :class="{
															required: !contract.customer,
														}" -->
												</v-autocomplete>
											</v-col>
										</v-row>

										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Secondary Tech</label>
											</v-col>

											<v-col lg="8" class="my-auto py-0">
												<v-autocomplete
													hide-details
													outlined
													:items="secondary_support_users"
													placeholder="Secondary Tech Support"
													item-text="display_name"
													item-value="id"
													v-model="contract.software[0].secondary_support"
													@input="support_user_select($event, 'secondary')"
												>
													<!-- :rules="[vrules.required(contract.customer, 'Customer')]" -->
													<!-- :class="{
															required: !contract.customer,
														}" -->
												</v-autocomplete>
											</v-col>
										</v-row>
									</template>
								</v-tab-item>
							</v-tabs-items>
						</div>
					</v-form>
				</template>

				<template v-slot:action>
					<v-btn
						:loading="pageLoading"
						class="white--text mr-2"
						depressed
						color="blue darken-4"
						tile
						:disabled="pageLoading || !formValid"
						v-on:click="createOrUpdateCustomer"
					>
						Save
					</v-btn>
					<v-btn depressed tile :disabled="pageLoading" v-on:click="cancleCreateContract"> Close </v-btn>
				</template>
			</Dialog>
		</div>
		<CreateSoftwareType
			v-if="softwareTypeDrawer"
			:title="'Software Type'"
			:drawer="softwareTypeDrawer"
			@close="softwareTypeDrawer = false"
			@success="onSuccess()"
			@deleted="contract.software[0].software_type = null"
		></CreateSoftwareType>
		<!-- <CreateServer
			v-if="serverTypeDrawer"
			:drawer="serverTypeDrawer"
			@close="serverTypeDrawer = false"
			:title="'Server'"
			:type-id="51"
			v-on:success="(serverTypeDrawer = false), $emit('refreshSettings')"
		></CreateServer> -->
	</div>
</template>
<style>
.extend-picker .v-input {
	min-width: 118.5%;
}
</style>

<script>
import { mapGetters } from "vuex";
import { POST, GET, DELETE } from "@/core/services/store/request.module";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
/* import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"; */
import MainMixin from "@/core/mixins/main.mixin.js";
import Chip from "@/view/components/Chip";
import { some, toString, cloneDeep, filter, isBoolean } from "lodash";
/* import MomentJS from "moment-timezone"; */
// import CreateServer from "@/view/components/create-server";
import DatePicker from "@/view/components/DatePicker";
import TextAreaInput from "@/view/components/TextAreaInput";
/* import TextInput from "@/view/components/TextInput"; */
/* import { toSafeInteger } from "lodash"; */
import MomentJS from "moment-timezone";
import Dialog from "@/view/components/Dialog";
import { ContractEventBus } from "@/core/lib/contract.lib";
import TextInput from "@/view/components/TextInput";
import SelectInput from "@/view/components/SelectInput";
import NumberInput from "@/view/components/NumberInput";
import CreateSoftwareType from "@/view/components/create-software-type";
import TagsAutoSearch from "@/view/components/TagsAutoSearch";
import objectPath from "object-path";
import RadioInputCustom from "@/view/components/RadioInputCustom";

export default {
	mixins: [MainMixin],
	name: "Create-Contract",
	props: {
		updateDialog: {
			type: Boolean,
			default: false,
		},
		customerList: {
			type: Array,
			default: () => [],
		},
		customerId: {
			type: Number,
			default: 0,
		},
		contractId: {
			type: Number,
			default: 0,
		},
		internalData: {
			type: Boolean,
			default: false,
		},
		settings: {
			type: [Object, Array],
			default: null,
		},
		/* updatedData: {
			type: Object,
			default() {
				return new Object();
			},
		}, */
	},
	watch: {
		/* contractId: {
			handler(param) {
				if (param) {
					this.getContract();
				}
			},
		}, */
		// customerData
		customerList: {
			deep: true,
			immediate: true,
			handler(param) {
				this.customerData = this.customerList || param;
			},
		},
		settings: {
			deep: true,
			immediate: true,
			handler(settings) {
				if (settings && Object.keys(settings).length) {
					this.software_type_list = settings.software;
					this.usersList = settings.users;
					this.secondary_support_users = settings.users;
					this.primary_support_users = settings.users;
					this.countryList = settings.country;
					this.phoneCodeList = settings.code;
					this.designationList = settings.designation;
					this.serverProvider = filter(settings.service_provider, (row) => row.is_hosting == 1);
					this.serverWeb = settings.server;
					this.payment_list = settings.payment_list;
					this.target_list = settings.target_list;
				}
			},
		},
		serverProvider: {
			deep: true,
			handler() {
				this.deleteCheck();
			},
		},
		software_type_list: {
			deep: true,
			handler() {
				this.deleteSoftTypeCheck();
			},
		},
		updateDialog: {
			immediate: true,
			handler(updateDialog) {
				if (updateDialog) {
					/* if(this.contractId == 0){
					   this.contract = {};
				   } */
					/* this.contract.title = '';
					   this.contract.customer = '';
					   this.contract.checkbox_type = '';
					   this.contract.seo_type = '';
					   this.contract.remark = '';
					   this.contract.status = '';
					   this.contract.customer_services = [];
					   this.contract.maintenance = [];
					   this.contract.domain = [];
					   this.contract.ssl =[];
					   this.contract.web = [];
					   this.contract.hosting = []; 
					*/
					if (this.contractId > 0) {
						this.getContract();
						this.getCustomerData();
					}
				}
			},
		},
	},
	data() {
		return {
			userList: [],
			pageTitle: "Create New Customer",
			formValid: true,
			today: new Date(),
			date_menu: false,
			pageLoading: false,
			//	openUploadModel:false,

			dataTab: "overviwe",
			checkData: [],
			usersList: [],
			preHostingType: null,
			preSslType: null,
			preWebType: null,
			preDomainType: null,
			preMainType: null,
			preSeoType: null,
			lead_id: 0,
			checkpayload: false,
			customerData: [],
			months: [
				{ value: "1", text: "1" },
				{ value: "2", text: "2" },
				{ value: "3", text: "3" },
				{ value: "4", text: "4" },
				{ value: "5", text: "5" },
				{ value: "6", text: "6" },
				{ value: "7", text: "7" },
				{ value: "8", text: "8" },
				{ value: "9", text: "9" },
				{ value: "10", text: "10" },
				{ value: "11", text: "11" },
				{ value: "12", text: "12" },
				{ value: "13", text: "13" },
				{ value: "14", text: "14" },
				{ value: "15", text: "15" },
				{ value: "16", text: "16" },
				{ value: "17", text: "17" },
				{ value: "18", text: "18" },
				{ value: "19", text: "19" },
				{ value: "20", text: "20" },
				{ value: "21", text: "21" },
				{ value: "22", text: "22" },
				{ value: "23", text: "23" },
				{ value: "24", text: "24" },
			],
			payment_list: [
				/* { value: "1", text: "Yearly" },
				{ value: "2", text: "Half Yearly" },
				{ value: "3", text: "Monthly" }, */
			],
			target_list: [],
			software_type_list: [],
			softwareTypeDrawer: false,
			// serverTypeDrawer: false,
			secondary_support_users: [],
			primary_support_users: [],

			contract: {
				cost: 0,
				sales_person: [],
				service_option: null,
				checkbox_type: [],
				title: null,
				seo_type: "single",
				customer: 0,
				remark: null,
				status: 1,
				//entity_type: "bthrust",
				entity_type: null,

				hosting: [
					{
						type: "bthrust",
						remark: null,
						is_filld: false,
					},
				],
				ssl: [
					{
						type: "bthrust",
						remark: null,
					},
				],
				web: [
					{
						type: "bthrust",
						remark: null,
					},
				],
				domain: [
					{
						type: "bthrust",
						remark: null,
					},
				],
				maintenance: [
					{
						type: "client",
						remark: null,
					},
				],
				customer_services: [
					{
						id: 0,
						keyword_monthly: null,
						keyword_free: null,
						total_time: "12",
						time_free: null,
						project_manager: null,
						remark: null,
						customer_urls: [
							{
								website_urls: null,
								url_target_google: 0,
							},
						],
					},
				],
				software: [
					{
						software_type: null,
						tags: null,
						sale_date: null,
						live_expected: null,
						live_actual: null,
						duration: "12",
						time_free: null,
						is_grant: false,

						service_provider: null,
						server: null,

						recurring_date: null,
						no_of_users: null,
						total_cost: null,
						cost_per_user: null,
						time_interval: "monthly",
						remark: null,

						project_manager_sg: null,
						primary_support: null,
						secondary_support: null,

						grant_type: "edg",
						filling_date: null,
						loa_date: null,
						system_live: null,
						claim_deadline: null,
					},
				],
			},

			statusList: [],
			countryList: [],
			//fileList: [],
			files: [
				{
					file: null,
					name: null,
				},
			],
			urlRules: [
				/* (v) => !!v || "Domain is required", */
				(v) =>
					v
						? /^(?!.*\.{2})(?!.*^\.)?(?:https?:\/\/)?(?:www\.)?(?!www\.)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/[^\s]*)?$/.test(
								v
						  ) || "Domain must be valid"
						: "SEO : Url is required",
			],
			phoneCodeList: [],
			designationList: [],
			serverProvider: [],
			serverWeb: [],
			keypressevt: null,
		};
	},
	/* props:{
		customerId: {
			type: Number,
			default: 0,
		},
	}, */
	components: {
		Dialog,
		// CreateServer,
		DatePicker,
		TextAreaInput,
		TextInput,
		Chip,
		SelectInput,
		NumberInput,
		CreateSoftwareType,
		TagsAutoSearch,
		RadioInputCustom,
	},

	methods: {
		maxValidation(users = 0) {
			if (users > 9999) this.contract.software[0].no_of_users = 0;
		},
		remove(item) {
			const index = this.contract.sales_person.indexOf(item.id);
			this.contract.sales_person.splice(index, 1);
		},
		getContractOption(customerId = 0) {
			this.contract.sales_person = [];
			this.$store
				.dispatch(GET, {
					url: `contract-option?customer_id=${customerId}`,
				})
				.then((data) => {
					//console.log(data);
					this.userList = data.users;
					//this.checkData = data;
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		filterServiceType(value = "") {
			if (value == "software") {
				this.contract.checkbox_type = ["software"];
				this.hostingFuncyion("software");
			} else if (value == "other") {
				this.contract.checkbox_type = []; //"seo", "hosting", "ssl", "web", "domain", "maintenance"
				//this.hostingFuncyion("seo");
				//this.hostingFuncyion("web");
			} else {
				this.contract.checkbox_type = null;
			}
		},
		/* uploadDialog() {
			this.openUploadModel = true;
		}, */
		getCustomerData() {
			this.$store
				.dispatch(GET, {
					url: `customer-list`,
				})
				.then((data) => {
					if (data && data.length) {
						this.customerData = data;
					} else {
						this.customerData = [];
					}
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		deleteCheck() {
			let found = this.serverProvider.findIndex(
				(v) => v.value == this.contract.software[0].service_provider
			);
			if (this.contract.software[0].service_provider && found == -1) {
				this.contract.software[0].service_provider = null;
			}
		},
		deleteSoftTypeCheck() {
			let found = this.software_type_list.findIndex(
				(v) => v.value == this.contract.software[0].software_type
			);
			if (found == -1) {
				this.contract.software[0].software_type = null;
			}
		},
		calculateUsersAndCost(value, field) {
			let cost_per_user = this.contract.software[0].cost_per_user
				? Number(cloneDeep(this.contract.software[0].cost_per_user))
				: 0;
			let total_amount = this.contract.software[0].total_cost
				? Number(cloneDeep(this.contract.software[0].total_cost))
				: 0;
			let no_of_users = this.contract.software[0].no_of_users
				? Number(cloneDeep(this.contract.software[0].no_of_users))
				: 0;

			if (field == "users") {
				no_of_users = value ? Number(value) : 0;
				this.contract.software[0].cost_per_user = Number(total_amount / no_of_users).toFixed(2);
			} else if (field == "total") {
				total_amount = value ? Number(value) : 0;
				this.contract.software[0].cost_per_user = Number(total_amount / no_of_users).toFixed(2);
			} else if (field == "cost") {
				cost_per_user = value ? Number(value) : 0;
				this.contract.software[0].total_cost = Number(cost_per_user * no_of_users).toFixed(2);
			}

			/* let users, totalAmount, costPerUser;
			if (field == "users") {
				users = +value;
				if (+total_amount) {
					this.contract.software[0].cost_per_user = +total_amount / +users;
				} else if (+cost_per_user) {
					this.contract.software[0].total_cost = +cost_per_user * +no_of_users;
				}
			} else if (field == "total") {
				totalAmount = +value;
				console.log({no_of_users: Boolean(+no_of_users)});
				if (+no_of_users) {
					this.contract.software[0].cost_per_user = totalAmount / +no_of_users;
				} else if (+cost_per_user) {
					this.contract.software[0].no_of_users = parseInt(+total_amount / +cost_per_user);
				}
			} else if (field == "cost") {
				costPerUser = +value;
				if (+total_amount) {
					this.contract.software[0].no_of_users = parseInt(+total_amount / +costPerUser);
				} else if(+no_of_users) {
					this.contract.software[0].total_cost = +costPerUser * +no_of_users;
				}
			} */
		},
		onSuccess() {
			// this.$emit('refreshSettings');
			ContractEventBus.$emit("settings-refresh");
		},
		limitDecimal($event, value) {
			let string = toString(value);

			// console.log($event.keyCode); //keyCodes string
			let keyCode = $event.keyCode ? $event.keyCode : $event.which;

			// only allow number and one dot
			if (
				((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || string.indexOf(".") != -1)) ||
				string.length > 11
			) {
				// 46 is dot
				$event.preventDefault();
			}

			// restrict to 2 decimal places
			if (string != null && string.indexOf(".") > -1 && string.split(".")[1].length > 1) {
				$event.preventDefault();
			}
		},
		hasKey(obj, key) {
			return objectPath.has(obj, key);
		},
		getValue(obj, key) {
			return objectPath.get(obj, key);
		},
		support_user_select(id, type) {
			if (type == "primary") {
				if (id) {
					let list = cloneDeep(this.secondary_support_users);
					let updated_list = cloneDeep(list.filter((v) => v.id != id));
					this.secondary_support_users = [...updated_list];
				} else {
					this.secondary_support_users = [...this.users];
				}
			} else if (type == "secondary") {
				if (id) {
					let list = cloneDeep(this.primary_support_users);
					let updated_list = cloneDeep(list.filter((v) => v.id != id));
					this.primary_support_users = [...updated_list];
				} else {
					this.primary_support_users = [...this.users];
				}
			}
		},
		cancleCreateContract() {
			this.contract.checkbox_type = [];
			this.clearOverview();
			this.resetSeoList();
			this.clearhosting();
			this.clearSsl();
			this.clearWeb();
			this.clearDomain();
			this.clearMaintenece();
			this.$emit("close", true);
			this.files = [
				{
					file: null,
					name: null,
				},
			];
		},
		urlInput(e) {
			let d_found = this.contract.checkbox_type.includes("domain");
			let s_found = this.contract.checkbox_type.includes("ssl");
			if (!d_found) {
				this.contract.checkbox_type.push("domain");
			}
			if (!s_found) {
				this.contract.checkbox_type.push("ssl");
			}
			if (!e) {
				let domainIdx = this.contract.checkbox_type.findIndex((v) => v == "domain");

				if (domainIdx > -1) {
					this.contract.checkbox_type.splice(domainIdx, 1);
				}
				let sslIdx = this.contract.checkbox_type.findIndex((v) => v == "ssl");
				if (sslIdx > -1) {
					this.contract.checkbox_type.splice(sslIdx, 1);
				}
			}
		},
		getContract() {
			this.checkData = [];
			this.$store
				.dispatch(GET, {
					url: `single-contract/${this.contractId}`,
				})
				.then((data) => {
					this.checkData = data;
					let contract = objectPath.get(data, "contract");
					let customer_services = objectPath.get(data, "customer_services");
					let software = objectPath.get(data, "software");
					let customer_hosting = objectPath.get(data, "customer_hosting");
					let customer_web = objectPath.get(data, "customer_web");
					let customer_ssl = objectPath.get(data, "customer_ssl").length
						? objectPath.get(data, "customer_ssl")
						: objectPath.get(data, "customer_ssl_2");
					let domain = objectPath.get(data, "domain").length
						? objectPath.get(data, "domain")
						: objectPath.get(data, "domain_2");
					let maintenance = objectPath.get(data, "maintenance");
					let attendie = objectPath.get(data, "attendie_ids");

					if (contract) {
						this.contract.title = contract.title;
						this.contract.customer = contract.customer;
						this.contract.checkbox_type = contract.checkbox_type;
						this.contract.seo_type = contract.seo_type;
						this.contract.entity_type = contract.entity_type;
						this.contract.remark = contract.remark;
						this.contract.status = contract.status;
						this.contract.cost = contract.cost;
						this.getContractOption(this.contract.customer);
					}
					this.contract.sales_person = attendie;
					this.contract.service_option = data.contract?.service_option;

					if (customer_services) {
						this.contract.customer_services = customer_services;
					}
					if (software) {
						this.contract.software = data.software;
					}
					/* if(this.customerId == 0){
						this.customerId = data.customer;
					} */
					if (customer_services.length) {
						/* alert(this.customer.customer_services[0].customer_urls.length) */
						this.contract.customer_services = customer_services;
						if (customer_services[0].customer_urls.length > 0) {
							this.contract.customer_services[0].customer_urls[0].keyword_files = [
								{
									customer_urls: [{}],
								},
							];
						} else {
							this.contract.customer_services[0].customer_urls = [
								{
									website_urls: null,
									url_target_google: null,
								},
							];
						}
					} else {
						this.contract.customer_services = [
							{
								keyword_monthly: null,
								keyword_free: null,
								total_time: "12",
								time_free: null,
								remark: null,
								customer_urls: [
									{
										website_urls: null,
										url_target_google: null,
									},
								],
							},
						];
					}

					if (customer_hosting) {
						if (customer_hosting.length) {
							this.contract.hosting = customer_hosting;
						} else {
							this.contract.hosting = [
								{
									remark: null,
									type: "bthrust",
									company_type: "bthrust",
									related_type: 0,
									customer: 0,
								},
							];
						}
					}

					if (customer_web) {
						if (customer_web.length) {
							this.contract.web = customer_web;
						} else {
							this.contract.web = [
								{
									remark: null,
									customer: 0,
									company_type: "bthrust",
								},
							];
						}
					}

					// this.contract.web = customer_web;
					// this.contract.ssl = data.customer_ssl;
					if (customer_ssl) {
						if (customer_ssl.length) {
							this.contract.ssl = customer_ssl;
						} else {
							this.contract.ssl = [
								{
									type: "bthrust",
									related_type: 0,
									company_type: "bthrust",
									customer: 0,
									remark: null,
								},
							];
						}
					}

					if (domain) {
						// this.contract.domain = data.domain;
						if (domain.length) {
							this.contract.domain = domain;
						} else {
							this.contract.domain = [
								{
									remark: null,
									company_type: "bthrust",
									type: "bthrust",
									customer: 0,
								},
							];
						}
					}

					// this.contract.maintenance = data.maintenance;
					if (maintenance) {
						if (maintenance.length) {
							this.contract.maintenance = maintenance;
						} else {
							this.contract.maintenance = [
								{
									type: "bthrust",
									company_type: "bthrust",
									customer: 0,
									remark: null,
								},
							];
						}
					}

					if (software.length) {
						this.contract.software = software;

						this.contract.software[0].time_interval = software[0]?.time_interval;
						// software[0].time_interval == 1 ? "monthly" : "yearly";
						this.contract.software[0].duration = software[0].duration.toString();

						// this.support_user_select(this.contract.software[0].primary_support, "primary");
						// this.support_user_select(this.contract.software[0].secondary_support, "secondary");

						let _tags = [];
						let tags = objectPath.get(software, "0.tags");
						if (tags && Array.isArray(tags) && tags.length) {
							tags.map((v) => {
								_tags.push({ ...v, text: v.tag });
							});
						}

						this.contract.software[0].tags = _tags;
						// saving with number value but coming in string
						// console.log("this.contract.software[0].server ==> ", this.contract.software[0].server.id);
						let server = objectPath.get(this.contract, "software.0.server");
						this.contract.software[0].server = server ? +server.id : 0;

						/* Deleted Software Type will be shown in the form  */
						let software_type_found = this.software_type_list.findIndex(
							(v) => v.value == this.contract.software[0].software_type
						);

						if (software_type_found < 0) {
							let raw_software_type = {
								name: this.contract.software[0].software_type.split("_").join(" "),
								value: this.contract.software[0].software_type,
							};

							this.software_type_list.push(raw_software_type);
						}

						this.contract.software[0].project_manager_sg = +software[0].project_manager_sg.id;
						this.contract.software[0].primary_support = +software[0].primary_support.id;
						this.contract.software[0].secondary_support = +software[0].secondary_support.id;
					} else {
						this.contract.software = [
							{
								software_type: null,
								tags: null,
								sale_date: null,
								live_expected: null,
								live_actual: null,
								duration: "12",
								time_free: null,
								is_grant: false,

								service_provider: null,
								server: null,

								recurring_date: null,
								no_of_users: null,
								total_cost: null,
								cost_per_user: null,
								time_interval: "monthly",
								remark: null,

								project_manager_sg: null,
								primary_support: null,
								secondary_support: null,

								grant_type: "edg",
								filling_date: null,

								loa_date: null,
								system_live: null,
								claim_deadline: null,
							},
						];
					}

					this.preHostingType =
						data.customer_hosting && data.customer_hosting[0] && data.customer_hosting[0].type
							? data.customer_hosting[0].type
							: null;
					this.preSslType =
						data.customer_ssl && data.customer_ssl[0] && data.customer_ssl[0].type
							? data.customer_ssl[0].type
							: null;
					this.preWebType =
						data.customer_web && data.customer_web[0] && data.customer_web[0].type
							? data.customer_web[0].type
							: null;
					this.preDomainType =
						data.domain && data.domain[0] && data.domain[0].type ? data.domain[0].type : null;
					this.preMainType =
						data.maintenance && data.maintenance[0] && data.maintenance[0].type
							? data.maintenance[0].type
							: null;
					this.preSeoType = data.contract && data.contract.seo_type ? data.contract.seo_type : null;

					/* this.preHostingType = data.customer_hosting[0].type;
					this.preSslType = data.customer_ssl[0].type;
					this.preWebType = data.customer_web[0].type;

					this.preDomainType = data.domain[0].type;
					this.preMainType = data.maintenance[0].type;
					this.preSeoType = data.contract.seo_type; */
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		resetSeoForm() {
			let seoIndex = this.contract.checkbox_type.findIndex((v) => v == "seo");
			if (seoIndex < 0) {
				this.resetSeoList();
			}
		},
		hostingFuncyion(value) {
			/* this.dataTab = value; */
			switch (value) {
				case "ssl":
					this.contract.ssl[0].type = "bthrust";
					if (
						this.contract.checkbox_type.find((v) => v == "seo") &&
						!this.contract.checkbox_type.find((v) => v == "ssl")
					) {
						this.contract.checkbox_type.push("ssl");
					}
					break;
				case "hosting":
					this.contract.hosting[0].type = "bthrust";
					break;
				case "web":
					this.contract.web[0].type = "bthrust";
					break;
				case "domain":
					this.contract.domain[0].type = "bthrust";
					if (
						this.contract.checkbox_type.find((v) => v == "seo") &&
						!this.contract.checkbox_type.find((v) => v == "domain")
					) {
						this.contract.checkbox_type.push("domain");
					}
					break;
				case "maintenance":
					this.contract.maintenance[0].type = "bthrust";
					break;
				case "seo":
					this.contract.seo_type = "single";
					if (!this.contract.checkbox_type.find((v) => v == "domain")) {
						this.contract.checkbox_type.push("domain");
					}
					if (!this.contract.checkbox_type.find((v) => v == "ssl")) {
						this.contract.checkbox_type.push("ssl");
					}
					// this.resetSeoForm();
					break;
			}
		},
		updateFile(index, file, value, data) {
			if (value == "keyword") {
				if (file && file.name) {
					data[index].name = file.name.split(".").slice(0, -1).join(".");
					data[index].suffix = `.${file.name.split(".").pop()}`;
				} else {
					data[index].name = null;
					data[index].suffix = null;
				}
			} else {
				if (file && file.name) {
					this.files[index].name = file.name.split(".").slice(0, -1).join(".");
					this.files[index].suffix = `.${file.name.split(".").pop()}`;
				} else {
					this.files[index].name = null;
					this.files[index].suffix = null;
				}
			}
		},
		addMore() {
			this.files.push({
				file: null,
				name: null,
			});
		},

		removeFile(index) {
			this.files.splice(index, 1);
			if (this.files.length < 1) {
				this.addMore();
			}
		},
		deleteServices(id, index) {
			if (id > 0) {
				this.$store
					.dispatch(DELETE, {
						url: `contract-service/${id}`,
					})
					.then(() => {
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: "Success ! Contract service deleted successfully.",
							},
						]);
						this.getContract();
						// this.getCustomerData();
					})
					.catch((error) => {
						console.log({
							error,
						});
					});
			} else {
				this.contract.customer_services.splice(index, 1);
			}
		},
		changeGrant(isGrant) {
			if (isGrant) {
				this.contract.software[0].grant_type = "edg";
			}
		},
		createOrUpdateCustomer() {
			const formErrors = this.validateForm(this.$refs["customer_submit"]);

			this.$refs["customer_submit"].validate();
			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}

			let validUrls = true;

			if (this.contract.checkbox_type && this.contract.checkbox_type.includes("seo")) {
				this.contract.customer_services.map((seo) => {
					seo.customer_urls.map((url) => {
						if (!url.website_urls) {
							validUrls = false;
							return false;
						}
					});
				});
			}

			if (!validUrls) {
				this.$store.commit(SET_ERROR, [
					{ message: "SEO : Url is required", model: true, timeout: 2000 },
				]);
				return false;
			}

			let software_errors = [];

			if (this.contract.checkbox_type.includes("software")) {
				if (!this.contract.software[0].software_type) {
					software_errors.push({ message: "Software : Software Type is required", model: true });
				}
				if (!this.contract.software[0].sale_date) {
					software_errors.push({ message: "Software : Sale Date is required", model: true });
				}
				if (!this.contract.software[0].duration) {
					software_errors.push({ message: "Software : Duration is required", model: true });
				}
			}

			if (software_errors.length) {
				this.$store.commit(SET_ERROR, [...software_errors]);
				return false;
			}

			if (!this.$refs["customer_submit"].validate()) {
				return false;
			}

			if (this.contract.checkbox_type.length == 0) {
				this.$store.commit(SET_ERROR, [
					{ model: true, message: "Atleast one service type is required." },
				]);
				return false;
			}
			this.pageLoading = true;

			let formData = new FormData();

			if (this.contract.sales_person) {
				formData.append("sales_person", this.contract.sales_person);
			}
			formData.append("cost", this.contract.cost);

			if (this.contract.checkbox_type) {
				formData.append("checkbox_type", this.contract.checkbox_type);
			}
			if (this.contract.seo_type) {
				formData.append("seo_type", this.contract.seo_type);
			}

			formData.append(
				"service_option",
				this.contract.service_option ? this.contract.service_option : "software"
			);

			if (this.contract.title) {
				formData.append("title", this.contract.title);
			}
			formData.append(
				"entity_type",
				this.contract.entity_type ? this.contract.entity_type : "bthrust"
			);
			if (this.contract.status) {
				formData.append("status", this.contract.status);
			}
			if (this.contract.remark) {
				formData.append("remark", this.contract.remark);
			}

			formData.append("customer", this.customerId ? this.customerId : this.contract.customer);

			const brand = some(this.contract.checkbox_type, (row) => row == "hosting");
			this.checkpayload = brand;
			if (this.checkpayload == true) {
				if (objectPath.get(this.contract, "hosting.0.remark")) {
					formData.append("hosting[0][remark]", this.contract.hosting[0].remark);
				}

				if (objectPath.get(this.contract, "hosting.0")) {
					formData.append("hosting[0][type]", "bthrust");
				}
				if (objectPath.get(this.contract, "hosting.0.company_type")) {
					formData.append("hosting[0][company_type]", this.contract.hosting[0].company_type);
				}
			}
			const ssl = some(this.contract.checkbox_type, (row) => row == "ssl");
			this.checkpayload = ssl;
			if (this.checkpayload == true) {
				if (objectPath.get(this.contract, "ssl.0")) {
					formData.append("ssl[0][type]", "bthrust");
				}
				if (objectPath.get(this.contract, "ssl.0.remark")) {
					formData.append("ssl[0][remark]", this.contract.ssl[0].remark);
				}
				// if (
				// 	this.contract &&
				// 	this.contract.ssl &&
				// 	this.contract.ssl[0] &&
				// 	this.contract.ssl[0].company_type
				// ) {
				// 	formData.append("ssl[0][company_type]", this.contract.ssl[0].company_type);
				// }
			}
			const web = some(this.contract.checkbox_type, (row) => row == "web");
			this.checkpayload = web;
			if (this.checkpayload == true) {
				if (objectPath.get(this.contract, "web.0.remark")) {
					formData.append("web[0][remark]", this.contract.web[0].remark);
				}

				if (objectPath.get(this.contract, "web.0")) {
					formData.append("web[0][type]", "bthrust");
				}
				// if (
				// 	this.contract &&
				// 	this.contract.web &&
				// 	this.contract.web[0] &&
				// 	this.contract.web[0].company_type
				// ) {
				// 	formData.append("web[0][company_type]", this.contract.web[0].company_type);
				// }
			}

			const domain = some(this.contract.checkbox_type, (row) => row == "domain");
			this.checkpayload = domain;
			if (this.checkpayload == true) {
				if (objectPath.get(this.contract, "domain.0.remark")) {
					formData.append("domain[0][remark]", this.contract.domain[0].remark);
				}

				if (objectPath.get(this.contract, "domain.0")) {
					formData.append("domain[0][type]", "bthrust");
				}
				// if (
				// 	this.contract &&
				// 	this.contract.domain &&
				// 	this.contract.domain[0] &&
				// 	this.contract.domain[0].company_type
				// ) {
				// 	formData.append("domain[0][company_type]", this.contract.domain[0].company_type);
				// }
			}

			const maintenance = some(this.contract.checkbox_type, (row) => row == "maintenance");
			this.checkpayload = maintenance;
			if (this.checkpayload == true) {
				if (objectPath.get(this.contract, "maintenance.0")) {
					formData.append("maintenance[0][type]", "bthrust");
				}
				if (objectPath.get(this.contract, "maintenance.0.remark")) {
					formData.append("maintenance[0][remark]", this.contract.maintenance[0].remark);
				}
				// if (
				// 	this.contract &&
				// 	this.contract.maintenance &&
				// 	this.contract.maintenance[0] &&
				// 	this.contract.maintenance[0].company_type
				// ) {
				// 	formData.append("maintenance[0][company_type]", this.contract.maintenance[0].company_type);
				// }
			}

			const software = some(this.contract.checkbox_type, (row) => row == "software");
			this.checkpayload = software;

			if (this.checkpayload == true && objectPath.get(this.contract, "software.0")) {
				if (objectPath.get(this.contract, "software.0.software_type")) {
					formData.append("software[0][software_type]", this.contract.software[0].software_type);
				}
				if (objectPath.get(this.contract, "software.0.tags") && this.contract.software[0].tags.length) {
					for (let i = 0; i < this.contract.software[0].tags.length; i++) {
						formData.append(`software[0][tags][${i}][text]`, this.contract.software[0].tags[i].text);
						formData.append(`software[0][tags][${i}][color]`, this.contract.software[0].tags[i].color);
					}
				}
				if (objectPath.get(this.contract, "software.0.sale_date")) {
					formData.append("software[0][sale_date]", this.contract.software[0].sale_date);
				}
				if (objectPath.get(this.contract, "software.0.live_expected")) {
					formData.append("software[0][live_expected]", this.contract.software[0].live_expected);
				}
				if (objectPath.get(this.contract, "software.0.live_actual")) {
					formData.append("software[0][live_actual]", this.contract.software[0].live_actual);
				}
				if (objectPath.get(this.contract, "software.0.duration")) {
					formData.append("software[0][duration]", this.contract.software[0].duration);
				}
				if (objectPath.get(this.contract, "software.0.time_free")) {
					formData.append("software[0][time_free]", this.contract.software[0].time_free);
				}

				formData.append("software[0][is_grant]", this.contract.software[0].is_grant ? 1 : 0);

				if (objectPath.get(this.contract, "software.0.service_provider")) {
					formData.append("software[0][service_provider]", this.contract.software[0].service_provider);
				}
				if (objectPath.get(this.contract, "software.0.server")) {
					formData.append("software[0][server]", this.contract.software[0].server);
				}
				if (objectPath.get(this.contract, "software.0.recurring_date")) {
					formData.append("software[0][recurring_date]", this.contract.software[0].recurring_date);
				}
				if (objectPath.get(this.contract, "software.0.no_of_users")) {
					formData.append("software[0][no_of_users]", +this.contract.software[0].no_of_users);
				}
				if (objectPath.get(this.contract, "software.0.total_cost")) {
					formData.append("software[0][total_cost]", this.contract.software[0].total_cost);
				}
				if (objectPath.get(this.contract, "software.0.cost_per_user")) {
					formData.append(
						"software[0][cost_per_user]",
						isBoolean(this.contract.software[0].cost_per_user)
							? this.contract.software[0].cost_per_user
							: 0
					);
				}
				if (objectPath.get(this.contract, "software.0.time_interval")) {
					formData.append("software[0][time_interval]", this.contract.software[0].time_interval);
				}
				if (objectPath.get(this.contract, "software.0.remark")) {
					formData.append("software[0][remark]", this.contract.software[0].remark);
				}
				if (objectPath.get(this.contract, "software.0.project_manager_sg")) {
					formData.append(
						"software[0][project_manager_sg]",
						this.contract.software[0].project_manager_sg
					);
				}
				if (objectPath.get(this.contract, "software.0.primary_support")) {
					formData.append("software[0][primary_support]", this.contract.software[0].primary_support);
				}
				if (objectPath.get(this.contract, "software.0.secondary_support")) {
					formData.append("software[0][secondary_support]", this.contract.software[0].secondary_support);
				}

				if (
					objectPath.get(this.contract, "software.0.grant_type") &&
					objectPath.get(this.contract, "software.0.is_grant")
				) {
					formData.append("software[0][grant_type]", this.contract.software[0].grant_type);
				}

				if (
					objectPath.get(this.contract, "software.0.filling_date") &&
					objectPath.get(this.contract, "software.0.is_grant")
				) {
					formData.append("software[0][filling_date]", this.contract.software[0].filling_date);
				}

				if (
					objectPath.get(this.contract, "software.0.loa_date") &&
					objectPath.get(this.contract, "software.0.is_grant")
				) {
					formData.append("software[0][loa_date]", this.contract.software[0].loa_date);
				}
				if (
					objectPath.get(this.contract, "software.0.system_live") &&
					objectPath.get(this.contract, "software.0.is_grant")
				) {
					formData.append("software[0][system_live]", this.contract.software[0].system_live);
				}
				if (
					objectPath.get(this.contract, "software.0.claim_deadline") &&
					objectPath.get(this.contract, "software.0.is_grant")
				) {
					formData.append("software[0][claim_deadline]", this.contract.software[0].claim_deadline);
				}
			}

			const seo = some(this.contract.checkbox_type, (row) => row == "seo");
			this.checkpayload = seo;
			if (this.checkpayload == true && objectPath.get(this.contract, "customer_services.0")) {
				for (let i = 0; i < this.contract.customer_services.length; i++) {
					if (objectPath.get(this.contract, `customer_services.${i}.id`)) {
						formData.append(`customer_services[${i}][id]`, this.contract.customer_services[i].id);
					}
					if (objectPath.get(this.contract, `customer_services.${i}.keyword_monthly`)) {
						formData.append(
							`customer_services[${i}][keyword_monthly]`,
							this.contract.customer_services[i].keyword_monthly
						);
					}
					if (objectPath.get(this.contract, `customer_services.${i}.keyword_free`)) {
						formData.append(
							`customer_services[${i}][keyword_free]`,
							this.contract.customer_services[i].keyword_free
						);
					}
					if (objectPath.get(this.contract, `customer_services.${i}.total_time`)) {
						formData.append(
							`customer_services[${i}][total_time]`,
							this.contract.customer_services[i].total_time
						);
					}
					if (objectPath.get(this.contract, `customer_services.${i}.time_free`)) {
						formData.append(
							`customer_services[${i}][time_free]`,
							this.contract.customer_services[i].time_free
						);
					}

					if (objectPath.get(this.contract, `customer_services.${i}.project_manager`)) {
						formData.append(
							`customer_services[${i}][project_manager]`,
							this.contract.customer_services[i].project_manager
						);
					}

					if (objectPath.get(this.contract, `customer_services.${i}.remark`)) {
						formData.append(`customer_services[${i}][remark]`, this.contract.customer_services[i].remark);
					}

					if (objectPath.get(this.contract, `customer_services.${i}.company_type`)) {
						formData.append(
							`customer_services[${i}][company_type]`,
							this.contract.customer_services[i].company_type
						);
					}

					for (let j = 0; j < this.contract.customer_services[i].customer_urls.length; j++) {
						if (objectPath.get(this.contract, `customer_services.${i}.customer_urls.${j}.website_urls`)) {
							formData.append(
								`customer_services[${i}][customer_urls][${j}][website_urls]`,
								this.contract.customer_services[i].customer_urls[j].website_urls
							);

							// let d_checkpayload = some(this.contract.checkbox_type, (row) => row == "domain");
							// let s_checkpayload = some(this.contract.checkbox_type, (row) => row == "ssl");

							// if (d_checkpayload == true && s_checkpayload == true) {
							// 	if (
							// 		this.contract &&
							// 		this.contract.domain &&
							// 		this.contract.ssl &&
							// 		this.contract.domain[0] &&
							// 		this.contract.ssl[0] &&
							// 		this.contract.ssl[0].remark &&
							// 		this.contract.domain[0].remark
							// 	) {
							// 		formData.append(
							// 			`domain[${this.contract.seo_type == "single" ? i : j}][remark]`,
							// 			this.contract.domain[0].remark
							// 		);
							// 		formData.append(
							// 			`ssl[${this.contract.seo_type == "single" ? i : j}][remark]`,
							// 			this.contract.ssl[0].remark
							// 		);
							// 	}
							// 	if (this.contract && this.contract.domain && this.contract.domain[0]) {
							// 		formData.append(`domain[${this.contract.seo_type == "single" ? i : j}][type]`, "bthrust");
							// 		formData.append(`ssl[${this.contract.seo_type == "single" ? i : j}][type]`, "bthrust");
							// 	}
							// 	if (this.contract && this.contract.domain && this.contract.domain[0]) {
							// 		formData.append(
							// 			`domain[${this.contract.seo_type == "single" ? i : j}][name]`,
							// 			this.contract.customer_services[i].customer_urls[j].website_urls
							// 		);
							// 		formData.append(
							// 			`ssl[${this.contract.seo_type == "single" ? i : j}][name]`,
							// 			this.contract.customer_services[i].customer_urls[j].website_urls
							// 		);
							// 	}
							// 	if (
							// 		this.contract &&
							// 		this.contract.domain &&
							// 		this.contract.domain[0] &&
							// 		this.contract.domain[0].company_type
							// 	) {
							// 		formData.append(
							// 			`domain[${this.contract.seo_type == "single" ? i : j}][company_type]`,
							// 			this.contract.domain[0].company_type
							// 		);
							// 		formData.append(
							// 			`ssl[${this.contract.seo_type == "single" ? i : j}][company_type]`,
							// 			this.contract.domain[0].company_type
							// 		);
							// 	}
							// }
						}
						if (
							objectPath.get(this.contract, `customer_services.${i}.customer_urls.${j}.url_target_google`)
							/* this.contract.customer_services[i].customer_urls[j].url_target_google.id */
						) {
							if (
								objectPath.get(
									this.contract,
									`customer_services.${i}.customer_urls.${j}.url_target_google.id`
								)
							) {
								formData.append(
									`customer_services[${i}][customer_urls][${j}][url_target_google]`,
									this.contract.customer_services[i].customer_urls[j].url_target_google.id
								);
							} else {
								formData.append(
									`customer_services[${i}][customer_urls][${j}][url_target_google]`,
									this.contract.customer_services[i].customer_urls[j].url_target_google
								);
							}
						}
						if (
							objectPath.get(this.contract, `customer_services.${i}.customer_urls.${j}.keyword_files`)
						) {
							if (
								objectPath.get(
									this.contract,
									`customer_services.${i}.customer_urls.${j}.keyword_files.0.file`
								)
							) {
								formData.append(
									`customer_services[${i}][customer_urls][${j}][keyword_files][0][file]`,
									this.contract.customer_services[i].customer_urls[j].keyword_files[0].file
								);
								formData.append(
									`customer_services[${i}][customer_urls][${j}][keyword_files][0][name]`,
									this.contract.customer_services[i].customer_urls[j].keyword_files[0].name
								);
							}
						}
					}
				}
			}
			for (let i = 0; i < this.files.length; i++) {
				if (this.files && this.files[i] && this.files[i].file) {
					formData.append(`file[${i}][file]`, this.files[i].file);
				}
				if (this.files && this.files[i] && this.files[i].name) {
					formData.append(`file[${i}][name]`, this.files[i].name);
				}
			}
			let requestTYPE = POST;
			let requestURL = "contract";

			if (this.contractId > 0) {
				requestURL = `contract/${this.contractId}`;
			}

			this.$store
				.dispatch(requestTYPE, {
					url: requestURL,
					data: formData,
				})
				.then(() => {
					if (this.contractId > 0) {
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: "Success ! Contract updated successfully.",
							},
						]);
					} else {
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: "Success ! Contract created successfully.",
							},
						]);
					}
					this.$emit("refress", true);
					ContractEventBus.$emit("parent-refresh", true);
					this.$refs["customer_submit"].reset(); // reset all fields even entity type
					// this.contract.entity_type = this.contract.entity_type || "bthrust";
					this.cancleCreateContract();
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},

		dateFormated(data, index, soeType) {
			if (data.live_actual && soeType == "software") {
				let date = MomentJS(data.live_actual, "YYYY-MM-DD");
				let months = +data.duration + (data.time_free ? +data.time_free : 0);
				date.add(months, "months");
				this.contract.software[index].recurring_date = date.subtract(1, "days").format("YYYY-MM-DD");
			}
		},

		// getSettings() {
		// 	const _this = this;
		// 	_this.$store
		// 		.dispatch(GET, {
		// 			url: "customer-setting",
		// 		})
		// 		.then((data) => {
		// 			_this.usersList = data.allUsers;
		// 			_this.countryList = data.country;
		// 			_this.phoneCodeList = data.code;
		// 			_this.designationList = data.designation;
		// 			_this.serverProvider = data.serverProvider;
		// 			_this.serverWeb = data.server;

		// 			_this.payment_list = data.payment_list;
		// 			_this.target_list = data.target_list;
		// 		})
		// 		.catch((error) => {
		// 			console.log({
		// 				error,
		// 			});
		// 		})
		// 		.finally(() => {
		// 			_this.pageLoading = false;
		// 		});
		// },

		addNresetSeoField(type = "") {
			if (this.contract.seo_type == "single") {
				const customer_services_obj = {
					keyword_monthly: null,
					keyword_free: null,
					total_time: "12",
					time_free: null,

					remark: null,
					customer_urls: [
						{
							url: null,
							target_google: null,
						},
					],
				};
				if (type == "reset") {
					this.contract.customer_services = [customer_services_obj];
				} else {
					this.contract.customer_services.push(customer_services_obj);
				}
			}
			if (this.contract.seo_type == "combined") {
				const customer_urls_obj = {
					url: null,
					target_google: null,
				};
				if (this.contract.customer_services[0].customer_urls) {
					this.contract.customer_services[0].customer_urls.push(customer_urls_obj);
				} else {
					this.contract.customer_services[0].customer_urls = customer_urls_obj;
				}
			}
		},
		removeSeoField(index, id) {
			if (id > 0) {
				this.$store
					.dispatch(DELETE, {
						url: `customer-url/${id}`,
					})
					.then(() => {
						this.getContract();
						// this.getCustomerData();
					})
					.catch((error) => {
						console.log({
							error,
						});
					});
			}
			if (this.contract.seo_type == "single") {
				this.contract.customer_services.splice(index, 1);
			}
			if (this.contract.seo_type == "combined") {
				this.contract.customer_services[0].customer_urls.splice(index, 1);
			}
		},
		resetSeoList(value) {
			if (this.preSeoType && this.preSeoType == value) {
				this.contract.customer_services = this.checkData.customer_services;
			} else {
				this.contract.customer_services = [
					{
						keyword_monthly: null,
						keyword_free: null,
						total_time: "12",
						time_free: null,
						remark: null,
						customer_urls: [
							{
								url: null,
								target_google: null,
							},
						],
					},
				];
				if (value == "single") {
					this.contract.customer_services[0].keyword_monthly = null;
					this.contract.customer_services[0].keyword_free = null;
					this.contract.customer_services[0].total_time = "12";
					this.contract.customer_services[0].time_free = null;
					this.contract.customer_services[0].remark = null;
					this.contract.customer_services[0].customer_urls[0].url = null;
					this.contract.customer_services[0].customer_urls[0].target_google = null;
				}
			}
		},
		clearhosting(value) {
			if (this.preHostingType && this.preHostingType == value) {
				this.contract.hosting = this.checkData.customer_hosting;
				this.contract.hosting[0].type = value;
			} else {
				this.contract.hosting = [
					{
						remark: null,
						type: value == "client" ? "client" : "bthrust",
						customer: 0,
						is_filld: false,
					},
				];
			}
		},

		clearSsl(value) {
			if (this.preSslType && this.preSslType == value) {
				this.contract.ssl = this.checkData.customer_ssl;
				this.contract.ssl[0].type = value;
			} else {
				this.contract.ssl = [
					{
						customer: 0,
						remark: null,
						type: value == "client" ? "client" : "bthrust",
					},
				];
			}
		},

		clearWeb(value) {
			if (this.preWebType && this.preWebType == value) {
				this.contract.web = this.checkData.customer_web;
				this.contract.web[0].type = value;
			} else {
				this.contract.web = [
					{
						cms_url: null,
						type: value == "client" ? "client" : "bthrust",
					},
				];
				if (value == "bthrust") {
					this.contract.web[0].remark = null;
					this.contract.web[0].customer = 0;
				}
			}
		},

		clearDomain(value) {
			if (this.preDomainType && this.preDomainType == value) {
				this.contract.domain = this.checkData.domain;
				this.contract.domain[0].type = value;
			} else {
				this.contract.domain = [
					{
						type: value == "client" ? "client" : "bthrust",
						customer: 0,
						remark: null,
					},
				];
			}
		},
		clearMaintenece(value) {
			if (this.preMainType && this.preMainType == value) {
				this.contract.maintenance = this.checkData.maintenance;
				this.contract.maintenance[0].type = value;
			} else {
				this.contract.maintenance = [
					{
						type: value == "client" ? "client" : "bthrust",
						customer: 0,
						remark: null,
					},
				];
			}
		},
		clearOverview() {
			this.contract.checkbox_type = [];
			this.contract.title = null;
			this.contract.seo_type = "single";
			this.contract.customer = 0;
			this.contract.remark = null;
			this.contract.status = 1;
			// this.contract.entity_type = "bthrust";
		},
	},
	computed: {
		...mapGetters(["errors"]),
	},
	mounted() {
		// this.getSettings();
		// this.getCustomerData();
		if (this.contractId) {
			/* 	this.getCustomer(); */
			this.pageTitle = "Update Customer";
		}
		/* this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Contract", disabled: true, href: "/contact" },
			{ text: "Create", disabled: true },
		]); */

		/* Should be commented until contract create and update form is in dialog popup */
		/* if (this.contractId) {
			this.$store.dispatch(SET_BREADCRUMB, [
				{ text: "Contract", disabled: true, href: "/contact" },
				{ text: "Update", disabled: true },
			]);
		} */
		/* this.contract.service_option = ""; */

		//this.getContractOption();
	},
	beforeMount() {
		/* this.contractId = this.$route.params.id; */
	},
};
</script>
